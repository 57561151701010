// React
import React, { useRef, useEffect } from 'react';
import { useParams } from "react-router-dom";

// Gsap
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

// Animate for title
import interText from '../../../functions/animatedText';
import blinkingLine from '../../../functions/blinkingLine';

// Components
import ButtonProductMint from "../../buttons/ButtonProductMint/ButtonProductMint";
import ButtonProductConnect from "../../buttons/ButtonProductConnect/ButtonProductConnect";

// Store
import products from "../../../store/products";
import contant from "../../../store/productPage";

// Style
import style from './productPage.scss';


const ProductPage = () => {

    const { id } = useParams();
    const productsId = id - 1;
    const data = products[productsId];

    const [count, setCount] = React.useState(0);

    const entryBlock = useRef(null);
    const exitBlock = useRef(null);
    const ref = useRef(null);
    const nameClass = useRef(null);

    const addItem = () => {
        setCount(count + 1);
    }

    const removeItem = () => {
        if (count > 0) {
            setCount(count - 1);
        }
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        var tl = gsap.timeline({
            scrollTrigger: {
                trigger: entryBlock.current,
                start: "top 400px",
                endTrigger: exitBlock.current,
                onEnter: run => {
                    interText([data.title], ref);
                    blinkingLine(nameClass.current);
                },
            }
        });
    }, []);

    return (
        <>
            <div className="productPage" ref={entryBlock}>
                <div className="product">
                    <h1 className="product__title interText animate__animated animate__fadeInUp wow">
                        <span id="interText__information" ref={ref}></span>
                        <span className="interText__line hidden" id="console-productPage" ref={nameClass}></span>
                    </h1>
                    <div className="product__union">
                        <div className="product__photo animate__animated animate__fadeInUp wow">
                            <img src={data.photo} alt={data.title} />
                        </div>
                        <div className="product__block">
                            <div className="product__name animate__animated animate__fadeInUp wow">{contant.collectionName}</div>
                            <div className="product__row animate__animated animate__fadeInUp wow">
                                <div className="product__row_line-active"></div>
                                <div className="product__row_name animate__animated animate__fadeInUp wow">
                                    {data.fullCount}
                                    <div className="product__row_name_line">/</div>
                                    {data.count}
                                </div>
                            </div>
                            <div className="product__count animate__animated animate__fadeInUp wow">
                                <div className="product__count_block">
                                    <div className="product__count_mine" onClick={() => removeItem()}>
                                    </div>
                                    <div className="product__count_number">
                                        {count}
                                    </div>
                                    <div className="product__count_plus" onClick={() => addItem()}>
                                    </div>
                                </div>
                                <div className="product__count_eth">
                                    {data.eth}
                                </div>
                            </div>
                            <div className="product__mint animate__animated animate__fadeInUp wow">
                                <ButtonProductMint text={contant.buttonMint} link={contant.buttonMintLink} />
                            </div>
                            <div className="product__connect animate__animated animate__fadeInUp wow" ref={exitBlock}>
                                <ButtonProductConnect text={contant.buttonConnect} link={contant.buttonConnectLink} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ProductPage;