const progressCarts = [
    {
        id: 'Q1',
        data: '2022',
        check: 1,
        items: [
            {
                title: 'Ideation and Conceptualization'
            },
            {
                title: 'Whitepaper Development and design'
            },
            {
                title: 'Team Formation and Recruitment'
            },
            {
                title: 'Fundraising and Financing stage'
            },
        ]
    },
    {
        id: 'Q2',
        data: '2022',
        check: 2,
        items: [
            {
                title: 'Minimum Viable Product (MVP)'
            },
            {
                title: 'Community Building and Engagement'
            },
            {
                title: 'Testnet Launch and Testing period'
            },
            {
                title: 'Initial Security Audit and Review'
            },
        ]
    },
    {
        id: 'Q3',
        data: '2022',
        check: 3,
        items: [
            {
                title: 'Mainnet Launch and Deployment'
            },
            {
                title: 'Listing on Cryptocurrency Exchanges'
            },
            {
                title: 'Upcoming…'
            },
            {
                title: ''
            },
        ]
    },
    {
        id: 'Q4',
        data: '2022',
        check: 4,
        items: [
            {
                title: '4 Stategic and Scalable partnership secured'
            },
            {
                title: 'Open Metaverse Integration'
            },
            {
                title: 'Omnipresent community development'
            },
            {
                title: 'Open Metaverse Integration'
            },
        ]
    },
    {
        id: 'Q5',
        data: '2022',
        check: 1,
        items: [
            {
                title: '1.1 Stategic and Scalable partnership secured'
            },
            {
                title: 'Open Metaverse Integration'
            },
            {
                title: 'Omnipresent community development'
            },
            {
                title: 'Open Metaverse Integration'
            },
        ]
    },
    {
        id: 'Q6',
        data: '2022',
        check: 2,
        items: [
            {
                title: '2.2 Stategic and Scalable partnership secured'
            },
            {
                title: 'Open Metaverse Integration'
            },
            {
                title: 'Omnipresent community development'
            },
            {
                title: 'Open Metaverse Integration'
            },
        ]
    },
    {
        id: 'Q7',
        data: '2022',
        check: 3,
        items: [
            {
                title: ' 3.3 Stategic and Scalable partnership secured'
            },
            {
                title: 'Open Metaverse Integration'
            },
            {
                title: 'Omnipresent community development'
            },
            {
                title: 'Open Metaverse Integration'
            },
        ]
    },
    {
        id: 'Q8',
        data: '2022',
        check: 4,
        items: [
            {
                title: ' 4.4 Stategic and Scalable partnership secured'
            },
            {
                title: 'Open Metaverse Integration'
            },
            {
                title: 'Omnipresent community development'
            },
            {
                title: 'Open Metaverse Integration'
            },
        ]
    },
]

export default progressCarts;