function blinkingLine(line) {
    var visible = true;
    var con = line;

    window.setInterval(function () {
        if (visible === true) {
            con.className = 'interText__line hidden';
            visible = false;
        } else {
            con.className = 'interText__line';
            visible = true;
        }
    }, 400)
}

export default blinkingLine;