const dataTeams = {
    name: 'Meet the',
    title: 'Team_',
    description: 'The only NFT platform where you can tap into a world of high-end luxury cars and real estate by joining a global community of crypto enthusiasts.',
    items: [
        {
            id: 1,
            photo: 'https://preview.vb.in.ua/rentalz/img/member.jpg',
            name: 'Vladislav Alectorov',
            position: 'Ceo'
        },
        {
            id: 2,
            photo: 'https://preview.vb.in.ua/rentalz/img/member.jpg',
            name: 'John Doe',
            position: 'Ceo'
        },
        {
            id: 3,
            photo: 'https://preview.vb.in.ua/rentalz/img/member.jpg',
            name: 'John Doe',
            position: 'Ceo'
        },
        {
            id: 4,
            photo: 'https://preview.vb.in.ua/rentalz/img/member.jpg',
            name: 'John Doe',
            position: 'Ceo'
        },
        {
            id: 5,
            photo: 'https://preview.vb.in.ua/rentalz/img/member.jpg',
            name: 'John Doe',
            position: 'Ceo'
        },
        {
            id: 6,
            photo: 'https://preview.vb.in.ua/rentalz/img/member.jpg',
            name: 'John Doe',
            position: 'Ceo'
        },
        {
            id: 7,
            photo: 'https://preview.vb.in.ua/rentalz/img/member.jpg',
            name: 'John Doe',
            position: 'Ceo'
        },
        {
            id: 8,
            photo: 'https://preview.vb.in.ua/rentalz/img/member.jpg',
            name: 'Vladislav Alectorov',
            position: 'Ceo'
        }
    ]
};

export default dataTeams;