// React
import React from 'react';
import { Link } from "react-router-dom";

// Style
import style from './buttonProductMint.scss';


function ButtonProductMint({ text, link, }) {
    return (
        <Link className="buttonProductMint" to={{ pathname: link }}><span>{text}</span></Link>
    )
}

export default ButtonProductMint;