// React
import React from 'react';
import { Link } from "react-router-dom";

// Style
import style from './buttonDecor.scss';


function ButtonDecor({ text, link, }) {
    return (
        <Link to={{ pathname: link }} className="buttonDecor">
            <div className="buttonDecor__mask">
                <div className="color">
                    <div className="buttonDecor__text">
                        <span>{text}</span>
                    </div>
                </div>
            </div>
        </Link >
    )
}

export default ButtonDecor;