// React
import React, { useRef, useEffect, useState } from 'react';

// Gsap
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

// Animate for title
import interText from '../../functions/animatedText';
import blinkingLine from '../../functions/blinkingLine';

// Store
import dataTeams from "../../store/dataTeams";

// Style
import style from '../Team/team.scss';


gsap.registerPlugin(ScrollTrigger);

function TeamPage() {

    const entryBlock = useRef(null);
    const exitBlock = useRef(null);
    const ref = useRef(null);
    const nameClass = useRef(null);

    const [startAnimation, setStartAnimation] = useState(true);

    useEffect(() => {
        window.scrollTo(0, 0);
        if (startAnimation) {
            const dote = document.querySelectorAll([".team__block_dot"]);
            const lineFirstLeft = document.querySelectorAll([".team__block_line_left-first"]);
            const lineFirstRight = document.querySelectorAll([".team__block_line_right-first"]);
            const boxLine = document.querySelector([".team__box_line"]);

            var tl = gsap.timeline({
                scrollTrigger: {
                    trigger: entryBlock.current,
                    start: "top 500px",
                    once: true,
                    onEnter: run => {
                        interText([dataTeams.title], ref);
                        blinkingLine(nameClass.current);

                        setTimeout(() => {
                            gsap.fromTo(".team__header .team__name",
                                {
                                    y: 30,
                                    opacity: 0,
                                },
                                {
                                    y: 0,
                                    duration: 3.5,
                                    ease: "power4.out",
                                    opacity: 1,
                                });
                        }, 100);

                        setTimeout(() => {
                            gsap.fromTo(".team__description",
                                {
                                    y: 30,
                                    opacity: 0,
                                },
                                {
                                    y: 0,
                                    duration: 3.5,
                                    ease: "power4.out",
                                    opacity: 1,
                                });
                        }, 100);

                        setTimeout(() => {
                            gsap.fromTo(".team__block_dot",
                                {
                                    y: -170,
                                    opacity: 0,
                                },
                                {
                                    y: 0,
                                    duration: 1.5,
                                    ease: "power4.out",
                                    opacity: 1,
                                });
                        }, 100);

                        setTimeout(() => {
                            lineFirstLeft.forEach((line) => {
                                line.style.display = 'block';
                            });
                        }, 900);
                        setTimeout(() => {
                            lineFirstRight.forEach((line) => {
                                line.style.display = 'block';
                            });
                        }, 900);

                        if (document.documentElement.clientWidth < 420) {
                            gsap.fromTo(".team__block:nth-of-type(1)",
                                {
                                    y: 100,
                                    opacity: 0,
                                },
                                {
                                    y: 0,
                                    duration: 1.5,
                                    ease: "power4.out",
                                    opacity: 1,
                                });
                        } else {
                            setTimeout(() => {
                                gsap.fromTo(".team__block:nth-of-type(1)",
                                    {
                                        y: 100,
                                        opacity: 0,
                                    },
                                    {
                                        y: 0,
                                        duration: 1.5,
                                        ease: "power4.out",
                                        opacity: 1,
                                    });
                            }, 2000);
                        }

                        setTimeout(() => {
                            gsap.fromTo(".team__box_line",
                                {
                                    opacity: 0,
                                },
                                {
                                    duration: 1.5,
                                    ease: "power4.out",
                                    opacity: 1,
                                });

                            if (document.documentElement.clientWidth < 768) {
                                gsap.fromTo(".team__box_line",
                                    {
                                        height: 0,
                                    },
                                    {
                                        height: 2100,
                                        duration: 30.5,
                                        ease: "power4.out",
                                    });
                            } else {
                                gsap.fromTo(".team__box_line",
                                    {
                                        height: 0,
                                    },
                                    {
                                        height: 3400,
                                        duration: 30.5,
                                        ease: "power4.out",
                                    });
                            }
                        }, 880);
                    },
                    endTrigger: exitBlock.current,
                }
            });

            var tl = gsap.timeline({
                scrollTrigger: {
                    trigger: '.team__block:nth-of-type(2)',
                    start: "top 400px",
                    once: true,
                    onEnter: run => {
                        gsap.fromTo(".team__block:nth-of-type(2)",
                            {
                                y: 100,
                                opacity: 0,
                            },
                            {
                                y: 0,
                                duration: 1.5,
                                ease: "power4.out",
                                opacity: 1,
                            })
                    },
                    endTrigger: exitBlock.current,
                }
            });
            var tl = gsap.timeline({
                scrollTrigger: {
                    trigger: '.team__block:nth-of-type(3)',
                    start: "top 400px",
                    once: true,
                    onEnter: run => {
                        gsap.fromTo(".team__block:nth-of-type(3)",
                            {
                                y: 100,
                                opacity: 0,
                            },
                            {
                                y: 0,
                                duration: 1.5,
                                ease: "power4.out",
                                opacity: 1,
                            })
                    },
                    endTrigger: exitBlock.current,
                }
            });
            var tl = gsap.timeline({
                scrollTrigger: {
                    trigger: '.team__block:nth-of-type(4)',
                    start: "top 400px",
                    once: true,
                    onEnter: run => {
                        gsap.fromTo(".team__block:nth-of-type(4)",
                            {
                                y: 100,
                                opacity: 0,
                            },
                            {
                                y: 0,
                                duration: 1.5,
                                ease: "power4.out",
                                opacity: 1,
                            })
                    },
                    endTrigger: exitBlock.current,
                }
            });
            var tl = gsap.timeline({
                scrollTrigger: {
                    trigger: '.team__block:nth-of-type(5)',
                    start: "top 400px",
                    once: true,
                    onEnter: run => {
                        gsap.fromTo(".team__block:nth-of-type(5)",
                            {
                                y: 100,
                                opacity: 0,
                            },
                            {
                                y: 0,
                                duration: 1.5,
                                ease: "power4.out",
                                opacity: 1,
                            })
                    },
                    endTrigger: exitBlock.current,
                }
            });
            var tl = gsap.timeline({
                scrollTrigger: {
                    trigger: '.team__block:nth-of-type(6)',
                    start: "top 400px",
                    once: true,
                    onEnter: run => {
                        gsap.fromTo(".team__block:nth-of-type(6)",
                            {
                                y: 100,
                                opacity: 0,
                            },
                            {
                                y: 0,
                                duration: 1.5,
                                ease: "power4.out",
                                opacity: 1,
                            })
                    },
                    endTrigger: exitBlock.current,
                }
            });
            var tl = gsap.timeline({
                scrollTrigger: {
                    trigger: '.team__block:nth-of-type(7)',
                    start: "top 400px",
                    once: true,
                    onEnter: run => {
                        gsap.fromTo(".team__block:nth-of-type(7)",
                            {
                                y: 100,
                                opacity: 0,
                            },
                            {
                                y: 0,
                                duration: 1.5,
                                ease: "power4.out",
                                opacity: 1,
                            })
                    },
                    endTrigger: exitBlock.current,
                }
            });
            var tl = gsap.timeline({
                scrollTrigger: {
                    trigger: '.team__block:nth-of-type(8)',
                    start: "top 400px",
                    once: true,
                    onEnter: run => {
                        gsap.fromTo(".team__block:nth-of-type(8)",
                            {
                                y: 100,
                                opacity: 0,
                            },
                            {
                                y: 0,
                                duration: 1.5,
                                ease: "power4.out",
                                opacity: 1,
                            })
                    },
                    endTrigger: exitBlock.current,
                }
            });
            setStartAnimation(false);
        }
    }, []);


    return (
        <div className="teamSection" ref={entryBlock}>
            <div className="team">
                <div className="team__header">
                    <div className="team__name section-name">{dataTeams.name}</div>
                    <h2 className="team__title interText">
                        <span id="interText__information" ref={ref}></span>
                        <span className="interText__line hidden" id="console-teamSection" ref={nameClass}></span>
                    </h2>
                    <div className="team__description text ">{dataTeams.description}</div>
                </div>
                <div className="team__box" ref={exitBlock}>
                    <span className="team__box_line"></span>
                    <span className="team__block_dot"></span>
                    <span className="team__block_line_left-first"></span>
                    <span className="team__block_line_right-first"></span>
                    {dataTeams.items.map((team) => (
                        <div className="team__block interText" key={team.id}>
                            <div className="team__block_line"></div>
                            <div className="team__block_line2"></div>
                            <div className="team__photo">
                                <img src={team.photo} alt={team.name} />
                            </div>
                            <div className="team__name">{team.name}</div>
                            <div className="team__position">{team.position}</div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default TeamPage