// React
import React, { useRef, useEffect, useState } from 'react';

// Gsap
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

// Animate for title
import interText from '../../../../functions/animatedText';
import blinkingLine from '../../../../functions/blinkingLine';

// Components
import FaqItem from "../../../../components/Accordion/Accordion";
import ButtonMainFaq from "../../../../components/buttons/ButtonMainFaq/ButtonMainFaq";

// Style
import style from "../Faq/faq.scss";


export default React.memo(function Faq({ data }) {

    const entryBlock = useRef(null);
    const exitBlock = useRef(null);
    const ref = useRef(null);
    const nameClass = useRef(null);

    const [startAnimation, setStartAnimation] = useState(true);

    useEffect(() => {
        if (startAnimation) {
            var tl = gsap.timeline({
                scrollTrigger: {
                    trigger: entryBlock.current,
                    start: "top 400px",
                    once: true,
                    onEnter: run => {
                        interText([data.title], ref);
                        blinkingLine(nameClass.current);

                        setTimeout(() => {
                            function faqItem() {
                                const items = document.querySelectorAll([".faq__accordion .accordion__item"]),
                                    btn = document.querySelector([".faq .faq__button"]);

                                let nextNumber = 0;
                                let countNumber = items.length;

                                let startAnimateItem = setInterval(() => {
                                    gsap.fromTo(items[nextNumber], { y: 30, opacity: 0, }, { y: 0, duration: 1.5, ease: "power4.out", opacity: 1, });

                                    nextNumber++;

                                    if (nextNumber === countNumber) {
                                        clearTimeout(startAnimateItem);

                                        setTimeout(() => {
                                            gsap.fromTo(".faq__button", { y: 20, opacity: 0, }, { y: 0, duration: 1.5, ease: "power4.out", opacity: 1, })
                                        }, 500);
                                    }
                                }, 500);
                            }
                            faqItem();
                        }, 100);
                    },
                    endTrigger: exitBlock.current,
                }
            });
            setStartAnimation(false);
        }
    }, []);


    return (
        <div className="faq" ref={entryBlock}>
            <h2 className="faq__title interText">
                <span id="interText__information" ref={ref}></span>
                <span className="interText__line hidden" id="console-faq-section" ref={nameClass}></span>
            </h2>
            <div className="faq__union">
                <div className="faq__accordion accordion">
                    {data.items.slice(0, 5).map(({ title, content, index }) => (
                        <FaqItem title={title} content={content} key={index} />
                    ))}
                </div>
                <div className="faq__button">
                    <ButtonMainFaq text={data.button} link={data.link} />
                </div>
            </div>
        </div>
    )
}); 