const offerHome = {
    title: 'Experience Luxury Living, In community',
    titleDecor: 'powered by NFTS',
    button: 'Get started',
    link: '/5'
};

const joinHome = {
    title: 'Join the global community of NFts enthusiasts',
    description: 'The only NFT platform where you can tap into a world of high-end luxury cars and real estate by joining a global community of crypto enthusiasts.',
    photo: 'https://preview.vb.in.ua/rentalz/img/join-full-photo.png',
    photoSmall: 'https://preview.vb.in.ua/rentalz/img/join-small-photo.jpg',
};

const statisticsHome = {
    nameSection: 'About us',
    title: 'Luxury Living for Crypto Elite_',
    description: 'Prioritize technology and digital assets, such as investing in NFTs or owning a private NFT collection. Get access to high-end properties, exclusive travel experiences, and access to private clubs and events.',
    photo_1: 'https://preview.vb.in.ua/rentalz/img/aabout-1.jpg',
    photo_2: 'https://preview.vb.in.ua/rentalz/img/aabout-2-second.jpg',
    photo_3: 'https://preview.vb.in.ua/rentalz/img/aabout-3.jpg',
    button: 'Get started',
    link: '/1',
    countTitleFirst: 'Members in community',
    countNumberFirst: '5',
    countTitleSecond: 'NFTs in the marketplace',
    countNumberSecond: '200',
    countTitleThird: 'Total volume of transaction',
    countNumberThird: '300',
};

const videoHome = {
    title: 'Watch before start_',
    urlVideo: 'www.videohere.com',
};

const benefitsHome = {
    title: 'You will get_',
    ico: 'https://preview.vb.in.ua/rentalz/img/benefits-photo.jpg',
    icoSecond: 'https://preview.vb.in.ua/rentalz/img/benefits-car.png',
    video: 'https://preview.vb.in.ua/rentalz/img/car-video.mp4',
    icoMobile: 'https://preview.vb.in.ua/rentalz/img/benefits-car-mobile.png',
    items: [
        {
            id: 1,
            ico: 'https://preview.vb.in.ua/rentalz/img/benefits-luxury.svg',
            title: 'Gamifying Luxury',
            description: 'Unlock a life of luxury experiences and a higher social status by becoming part of our fast-growing community of 10,000+ members.',
        },
        {
            id: 2,
            ico: 'https://preview.vb.in.ua/rentalz/img/benefits-reach.svg',
            title: 'Global Reach',
            description: 'Explore new opportunities for innovation, and financial rewards while participating in a growing ecosystem of like-minded individuals.',
        },
        {
            id: 3,
            ico: 'https://preview.vb.in.ua/rentalz/img/benefits-access.svg',
            title: 'Exclusive Access',
            description: 'Exclusive access to unique digital assets, limited edition collections, and specialized services that are not available in traditional markets.',
        }
    ]
};

const howItWorksHome = {
    title: ' How it works_',
    id: 1,
    items: [
        {
            id: 1,
            step: 'Step 1',
            stepName: 'Mint',
            description: 'Refer to the process of creating a unique digital asset on a blockchain network that is verified as one-of-a-kind and cannot be replicated or interchanged with any other asset.',
            img: 'https://preview.vb.in.ua/rentalz/img/itworks-item-photo-1.jpg',
            stepDone: true,
        },
        {
            id: 2,
            step: 'Step 2',
            stepName: 'Stake',
            description: 'The process of holding or locking them in a smart contract for a certain period of time to earn rewards or other benefits on a blockchain network.',
            img: 'https://preview.vb.in.ua/rentalz/img/itworks-item-photo-2.jpg',
            stepDone: false,
        },
        {
            id: 3,
            step: 'Step 3',
            stepName: 'Redeem',
            description: 'Exchange or convert FTP it back into its underlying asset, such as a physical or digital item, or into another form of cryptocurrency or fiat currency on a blockchain network.',
            img: 'https://preview.vb.in.ua/rentalz/img/itworks-item-photo-3.jpg',
            stepDone: false,
        },
        {
            id: 4,
            step: 'Step 4',
            stepName: 'Get reward',
            description: 'Receive incentives or benefits, such as discounts or special access, for using Senegis NFTs to purchase or access physical goods or services in the real world.',
            img: 'https://preview.vb.in.ua/rentalz/img/itworks-item-photo-4.jpg',
            stepDone: false,
        }
    ]
};

const collectionHome = {
    title: 'Collection_',
    description: 'The only NFT platform where you can tap into a world of high-end luxury cars and real estate by joining a global community of crypto enthusiasts.',
    button: 'Mint now',
    link: '/3'
};

const progressHome = {
    name: 'Roadmap',
    title: 'Distribution progress_',
}

export { offerHome, joinHome, statisticsHome, videoHome, benefitsHome, howItWorksHome, collectionHome, progressHome }; 