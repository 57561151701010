// React
import React, { useEffect } from 'react';

// Components
import Button from "../../components/buttons/ButtonMain/ButtonMain";
import ButtonMainMobile from "../../components/buttons/ButtonMainMobile/ButtonMainMobile";

// Store
import data from "../../store/notFound";

// Style
import style from './notFound.scss';


function NotFound() {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <div className="notFound">
            <div className="notFound__block">
                <div className="block">
                    <div className="block__name section-name">{data.name}</div>
                    <h1 className="block__title" title={data.title}>
                        {data.title}
                    </h1>
                    <div className="notFound__button">
                        <Button text={data.button} link={data.link} />
                    </div>
                    <div className="notFound__button_mobile">
                        <ButtonMainMobile text={data.button} link={data.link} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NotFound