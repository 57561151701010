export function progressSlider(carouselRef, figureRef, navRef, lineActive, nameClass) {
    var figure = figureRef.current;
    var nav = navRef.current;
    var numImages = figure.childElementCount;
    var theta = 2 * Math.PI / numImages;
    var cartBlock = 0;
    // var block = blockRef.current;
    var line = lineActive.current;
    var allCarts = document.querySelectorAll([".progress__block"]);

    nav.addEventListener('click', onClick);

    // cart.style.opacity = '1';
    function onClick(e) {
        e.stopPropagation();

        var t = e.target;
        if (t.tagName.toUpperCase() != 'BUTTON')
            return;

        if (t.classList.contains('control__next')) {
            cartBlock++;

            allCarts.forEach((cart, id) => {
                // cart.style.opacity = '0.1';

                if (id === cartBlock) {
                    cart.style.opacity = '1';
                }
                if (cartBlock > 7) {
                    cartBlock = 0;
                    id = 0;
                    allCarts[0].style.opacity = '1';
                }
            });

            if (cartBlock === 0) {
                // block.innerHTML = 12.5 + '%';
                line.style.width = 12.5 + '%';
            } else if (cartBlock === 1) {
                // block.innerHTML = 12.5 * 2 + '%';
                line.style.width = 12.5 * 2 + '%';
            } else if (cartBlock === 2) {
                // block.innerHTML = 12.5 * 3 + '%';
                line.style.width = 12.5 * 3 + '%';
            } else if (cartBlock === 3) {
                // block.innerHTML = 12.5 * 4 + '%';
                line.style.width = 12.5 * 4 + '%';
            } else if (cartBlock === 4) {
                // block.innerHTML = 12.5 * 5 + '%';
                line.style.width = 12.5 * 5 + '%';
            } else if (cartBlock === 5) {
                // block.innerHTML = 12.5 * 6 + '%';
                line.style.width = 12.5 * 6 + '%';
            } else if (cartBlock === 6) {
                // block.innerHTML = 12.5 * 7 + '%';
                line.style.width = 12.5 * 7 + '%';
            } else if (cartBlock === 7) {
                // block.innerHTML = 12.5 * 8 + '%';
                line.style.width = 12.5 * 8 + '%';
            }
        }
        else {
            if (cartBlock > 0) {
                cartBlock--;
            }

            allCarts.forEach((cart, id) => {
                // cart.style.opacity = '0.1';

                if (id === cartBlock) {
                    // cart.style.opacity = '1';
                }
                if (cartBlock > 7) {
                    cartBlock = 0;
                    id = 0;
                    // allCarts[0].style.opacity = '1';
                }
            });

            if (cartBlock === 0) {
                // block.innerHTML = 12.5 + '%';
                line.style.width = 12.5 + '%';
            } else if (cartBlock === 1) {
                // block.innerHTML = 12.5 * 2 + '%';
                line.style.width = 12.5 * 2 + '%';
            } else if (cartBlock === 2) {
                // block.innerHTML = 12.5 * 3 + '%';
                line.style.width = 12.5 * 3 + '%';
            } else if (cartBlock === 3) {
                // block.innerHTML = 12.5 * 4 + '%';
                line.style.width = 12.5 * 4 + '%';
            } else if (cartBlock === 4) {
                // block.innerHTML = 12.5 * 5 + '%';
                line.style.width = 12.5 * 5 + '%';
            } else if (cartBlock === 5) {
                // block.innerHTML = 12.5 * 6 + '%';
                line.style.width = 12.5 * 6 + '%';
            } else if (cartBlock === 6) {
                // block.innerHTML = 12.5 * 7 + '%';
                line.style.width = 12.5 * 7 + '%';
            } else if (cartBlock === 7) {
                // block.innerHTML = 12.5 * 8 + '%';
                line.style.width = 12.5 * 8 + '%';
            }
        }

        figure.style.transform = `rotateY(${cartBlock * -theta}rad)`;
    }

    allCarts[0].style.opacity = '1';

    if (cartBlock === 0) {
        // block.innerHTML = 12.5 + '%';
        line.style.width = 12.5 + '%';
    }
}