// React
import React from 'react';

// Style
import style from './buttonMainSend.scss';


function ButtonMainSend({ text }) {
    return (
        <button className="buttonMainSend">
            <div className="buttonMainSend__mask">
                <div className="color">
                    <div className="buttonMainSend__text">
                        <span>{text}</span>
                    </div>
                </div>
            </div>
        </button>
    )
}

export default ButtonMainSend;