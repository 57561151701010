const nft = [
    {
        id: 1,
        photo: 'https://preview.vb.in.ua/rentalz/img/product-car-new-1.jpg',
        title: 'Solaris GT',
        price: 150,
        fullCount: '645',
        count: '3,333',
        eth: '0.220 ETH',
    },
    {
        id: 2,
        photo: 'https://preview.vb.in.ua/rentalz/img/product-car-new-2.jpg',
        title: 'Horizon LX',
        price: 175,
        fullCount: '645',
        count: '3,333',
        eth: '0.220 ETH',
    },
    {
        id: 3,
        photo: 'https://preview.vb.in.ua/rentalz/img/product-car-new-4.jpg',
        title: 'Thunderbolt X',
        price: 200,
        fullCount: '645',
        count: '3,333',
        eth: '0.220 ETH',
    },
    {
        id: 4,
        photo: 'https://preview.vb.in.ua/rentalz/img/product-car-new-5.jpg',
        title: 'Eclipse RS',
        price: 225,
        fullCount: '645',
        count: '3,333',
        eth: '0.220 ETH',
    },
    {
        id: 5,
        photo: 'https://preview.vb.in.ua/rentalz/img/product-car-new-6.jpg',
        title: 'Velocity ST',
        price: 250,
        fullCount: '645',
        count: '3,333',
        eth: '0.220 ETH',
    },
    {
        id: 6,
        photo: 'https://preview.vb.in.ua/rentalz/img/product-car-new-7.jpg',
        title: 'Oceanic SE',
        price: 275,
        fullCount: '645',
        count: '3,333',
        eth: '0.220 ETH',
    },
    {
        id: 7,
        photo: 'https://preview.vb.in.ua/rentalz/img/product-car-new-8.jpg',
        title: 'Titan R',
        price: 300,
        fullCount: '645',
        count: '3,333',
        eth: '0.220 ETH',
    },
    {
        id: 8,
        photo: 'https://preview.vb.in.ua/rentalz/img/product-car-new-9.jpg',
        title: 'Phoenix Z',
        price: 325,
        fullCount: '645',
        count: '3,333',
        eth: '0.220 ETH',
    },
    {
        id: 9,
        photo: 'https://preview.vb.in.ua/rentalz/img/product-car-new-10.jpg',
        title: 'Nova SS',
        price: 350,
        fullCount: '645',
        count: '3,333',
        eth: '0.220 ETH',
    },
    {
        id: 10,
        photo: 'https://preview.vb.in.ua/rentalz/img/product-car-new-1.jpg',
        title: 'Aurora S',
        price: 375,
        fullCount: '645',
        count: '3,333',
        eth: '0.220 ETH',
    },
    {
        id: 11,
        photo: 'https://preview.vb.in.ua/rentalz/img/product-car-new-2.jpg',
        title: 'Huracan Spider 11',
        price: 300,
        fullCount: '645',
        count: '3,333',
        eth: '0.220 ETH',
    },
    {
        id: 12,
        photo: 'https://preview.vb.in.ua/rentalz/img/product-car-new-3.jpg',
        title: 'Huracan Spider 12',
        price: 300,
        fullCount: '645',
        count: '3,333',
        eth: '0.220 ETH',
    },
    {
        id: 13,
        photo: 'https://preview.vb.in.ua/rentalz/img/product-car-new-4.jpg',
        title: 'Huracan Spider 13',
        price: 300,
        fullCount: '645',
        count: '3,333',
        eth: '0.220 ETH',
    },
    {
        id: 14,
        photo: 'https://preview.vb.in.ua/rentalz/img/product-car-new-5.jpg',
        title: 'Huracan Spider 14',
        price: 300,
        fullCount: '645',
        count: '3,333',
        eth: '0.220 ETH',
    },
];

export default nft;