const menuHeader = {

    menuFirst: [
        {
            id: 1,
            name: 'NFT collection',
            link: '/'
        },
        {
            id: 2,
            name: 'Updates',
            link: '/'
        }
    ],

    menuSecond: [
        {
            id: 1,
            ico: 'https://preview.vb.in.ua/rentalz/img/photo-item-menu.svg',
            name: 'NFT collection',
            link: '/'
        },
        {
            id: 2,
            ico: 'https://preview.vb.in.ua/rentalz/img/photo-item-menu.svg',
            name: 'Updates',
            link: '/'
        },
        {
            id: 3,
            ico: 'https://preview.vb.in.ua/rentalz/img/photo-item-menu.svg',
            name: 'Our story',
            link: '/about'
        },
        {
            id: 4,
            ico: 'https://preview.vb.in.ua/rentalz/img/photo-item-menu.svg',
            name: 'Team',
            link: '/team'
        },
        {
            id: 5,
            ico: 'https://preview.vb.in.ua/rentalz/img/photo-item-menu.svg',
            name: 'FAQ',
            link: '/faq'
        },
        {
            id: 6,
            ico: 'https://preview.vb.in.ua/rentalz/img/photo-item-menu.svg',
            name: 'Blog',
            link: '/blog'
        },
        {
            id: 7,
            ico: 'https://preview.vb.in.ua/rentalz/img/photo-item-menu.svg',
            name: 'Contacts',
            link: '/contacts'
        }
    ],

    menuThird: [
        {
            id: 1,
            name: 'Privacy policy',
            link: '/'
        },
        {
            id: 2,
            name: 'Terms of use',
            link: '/'
        }
    ]
};

const menuFooter = {

    menuFirst1: [

        {
            id: 1,
            name: 'NFT collection',
            link: '/'

        },
        {
            id: 2,
            name: 'Our story',
            link: '/about'
        },
        {
            id: 3,
            name: 'Team',
            link: '/team'
        },
        {
            id: 4,
            name: 'FAQ',
            link: '/faq'
        },
        {
            id: 5,
            name: 'Blog',
            link: '/blog'
        },
        {
            id: 6,
            name: 'Contacts',
            link: '/contacts'
        }
    ],

    menuSecond2: [
        {
            id: 1,
            name: 'Privacy policy',
            link: '/',
        },
        {
            id: 2,
            name: 'Terms of use',
            link: '/',
        },
    ]
}

export { menuHeader, menuFooter }; 