// React
import React, { useRef, useEffect, useState } from 'react';

// Gsap
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

// Animate title
import interText from '../../../../functions/animatedText';
import blinkingLine from '../../../../functions/blinkingLine';

// Components
import ButtonMain from "../../../../components/buttons/ButtonMain/ButtonMain";
import ProductItems from "../../../../components/products/ProductItem/ProductItems";

// Style
import style from "./collection.scss";


export default React.memo(function Collection({ data }) {

    const entryBlock = useRef(null);
    const exitBlock = useRef(null);
    const ref = useRef(null);
    const nameClass = useRef(null);

    const [startAnimation, setStartAnimation] = useState(true);

    useEffect(() => {
        if (startAnimation) {
            var tl = gsap.timeline({
                scrollTrigger: {
                    trigger: entryBlock.current,
                    start: "top 500px",
                    once: true,
                    endTrigger: exitBlock.current,
                    onEnter: run => {
                        interText([data.title], ref);
                        blinkingLine(nameClass.current);

                        setTimeout(() => {
                            gsap.fromTo(".collection__description", { y: 30, opacity: 0, }, { y: 0, duration: 2.5, ease: "power4.out", opacity: 1, })
                        }, 100);

                        setTimeout(() => {
                            gsap.fromTo(".collection__button", { y: 30, opacity: 0, }, { y: 0, duration: 2.5, ease: "power4.out", opacity: 1, })
                        }, 300);

                        setTimeout(() => {
                            gsap.fromTo(".products", { y: 30, opacity: 0, }, { y: 0, duration: 2.5, ease: "power4.out", opacity: 1, })
                        }, 400);
                    },
                }
            });
            setStartAnimation(false);
        }
    }, []);


    return (
        <div className="collection" ref={entryBlock}>
            <div className="collection__box">
                <div className="collection__block">
                    <h2 className="collection__title interText ">
                        <span id="interText__information" ref={ref}></span>
                        <span className="interText__line hidden" id="console-collection" ref={nameClass}></span>
                    </h2>
                    <div className="collection__description_box">
                        <div className="collection__description text">
                            {data.description}
                        </div>
                    </div>
                    <div className="collection__button_box">
                        <div className="collection__button">
                            <ButtonMain text={data.button} link={data.link} />
                        </div>
                    </div>
                </div>
                <div className="collection__products_box">
                    <div className="collection__products">
                        <div className="products">
                            <ProductItems />
                        </div>
                    </div>
                </div>
                <div className="collection__button_mob ">
                    <ButtonMain text={data.button} link={data.link} />
                </div>
            </div>
            <div className="collection-loyer" ref={exitBlock}></div>
        </div>
    )
});  