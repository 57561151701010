// React
import React from 'react'
import { Link } from "react-router-dom";

// Style
import style from './buttonDefaultHeader.scss';


function ButtonDefaultHeader({ text, link }) {
    return (
        <Link className='buttonDefaultHeader' to={{ pathname: link }}>
            <div className="buttonDefaultHeader__mask">
                <div className="color">
                    <span className="buttonDefaultHeader__text">{text}</span>
                </div>
            </div>
        </Link>
    )
}

export default ButtonDefaultHeader;