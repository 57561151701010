// React
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

// Components
import nft from "../../../store/products";

// Style
import style from "../ProductItem/productItem.scss";


function ProductItems() {

    const [addClassSafari, setAddClassSafari] = useState(false);

    useEffect(() => {
        const is_safari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

        if (is_safari) {
            setAddClassSafari(true);
        }
    }, []);


    return (
        <>
            {nft.slice(0, 14).map((nft) => (
                <Link to={`products/nft/${nft.id}`} className="productItem" key={nft.id} >
                    <div className="product card">
                        <div className="product__photo_mask">
                            <div className={addClassSafari ? 'back backSaf' : 'back'}></div>
                        </div>
                        <div className="product__photo">
                            <img src={nft.photo} alt={nft.title} />
                        </div>
                        <div className="product__block">
                            <h4 className="product__title">{nft.title}</h4>
                            <div className="product__price">{nft.price}</div>
                        </div>
                    </div>
                </Link>
            ))
            }
        </>
    )
}

export default ProductItems