// React
import React, { useRef, useEffect, useState } from 'react';

// Gsap
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

// Animate for title
import interText from '../../../../functions/animatedText';
import blinkingLine from '../../../../functions/blinkingLine';

// Style
import style from "./join.scss";


export default React.memo(function Join({ data }) {

    const entryBlock = useRef(null);
    const exitBlock = useRef(null);
    const ref = useRef(null);
    const nameClass = useRef(null);

    const [startAnimation, setStartAnimation] = useState(true);

    useEffect(() => {
        if (startAnimation) {
            if (document.documentElement.clientWidth < 420) {
                var tl = gsap.timeline({
                    scrollTrigger: {
                        trigger: entryBlock.current,
                        start: "top 400px",
                        once: true,
                        onEnter: run => {
                            interText([data.title], ref);
                            blinkingLine(nameClass.current);

                            setTimeout(() => {
                                // Left photo
                                gsap.fromTo(".join__photo_box",
                                    {
                                        y: 150,
                                        scale: 1.1,
                                        opacity: 0
                                    },
                                    {
                                        y: 0,
                                        scale: 1,
                                        duration: 2.5,
                                        ease: "power4.out",
                                        opacity: 1,
                                    });
                            }, 800);

                            setTimeout(() => {
                                // Right photo
                                gsap.fromTo(".join__block_box .join__photo_mask",
                                    {
                                        y: 50,
                                        opacity: 0
                                    },
                                    {
                                        y: 0,
                                        duration: 2.5,
                                        ease: "power4.out",
                                        opacity: 1,
                                    });
                            }, 800);

                            // Right description
                            setTimeout(() => {
                                gsap.fromTo(".join__description_box",
                                    {
                                        y: 20,
                                        opacity: 0,
                                    },
                                    {
                                        y: 0,
                                        duration: 1.5,
                                        ease: "power4.out",
                                        opacity: 1,
                                    })
                            }, 800);
                        },
                        endTrigger: exitBlock.current,
                    }
                });
            } else {
                var tl = gsap.timeline({
                    scrollTrigger: {
                        trigger: entryBlock.current,
                        start: "top 200px",
                        once: true,
                        onEnter: run => {
                            interText([data.title], ref);
                            blinkingLine(nameClass.current);

                            // Left photo
                            gsap.fromTo(".join__photo_box",
                                {
                                    y: 150,
                                    scale: 1.1,
                                    opacity: 0
                                },
                                {
                                    y: 0,
                                    scale: 1,
                                    duration: 2.5,
                                    ease: "power4.out",
                                    opacity: 1,
                                });

                            // Left photo (scale)
                            gsap.fromTo(".join__photo_main",
                                {
                                    opacity: 0,
                                    borderTopRightRadius: 170,
                                    borderTopLeftRadius: 170,
                                    borderBottomRightRadius: 170,
                                    borderBottomLeftRadius: 50,
                                },
                                {
                                    scale: 1,
                                    duration: 2.5,
                                    ease: "expo.out",
                                    opacity: 1,
                                    borderTopRightRadius: 110,
                                    borderTopLeftRadius: 110,
                                    borderBottomRightRadius: 110,
                                    borderBottomLeftRadius: 110,
                                })

                            // Right photo
                            gsap.fromTo(".join__block_box .join__photo_mask",
                                {
                                    y: 50,
                                    opacity: 0
                                },
                                {
                                    y: 0,
                                    duration: 2.5,
                                    ease: "power4.out",
                                    opacity: 1,
                                });


                            var tl = gsap.timeline({
                                scrollTrigger: {
                                    trigger: '.join .join__union',
                                    start: "top 300px",
                                    end: "top top",
                                    scrub: true,
                                    endTrigger: exitBlock.current,
                                }
                            });
                            tl.fromTo('.join__photo_mask img',
                                {
                                    scale: 1.2,
                                },
                                {
                                    scale: 1,
                                    duration: 2.5,
                                    ease: "power4.out",
                                })

                            // Right description
                            setTimeout(() => {
                                gsap.fromTo(".join__description_box",
                                    {
                                        y: 20,
                                        opacity: 0,
                                    },
                                    {
                                        y: 0,
                                        duration: 1.5,
                                        ease: "power4.out",
                                        opacity: 1,
                                    })
                            }, 1400);
                        },
                        endTrigger: exitBlock.current,
                    }
                });
            }
            setStartAnimation(false);
        }
    }, []);


    return (
        <div className="join" ref={entryBlock}>
            <div className="join__back">
                <div className="join__back-k"></div>
                <div className="join__back_photo"></div>
            </div>
            <div className="join__union">
                <div className="join__block_1">
                    <div className="join__block_1_line"></div>
                    <div className="join__block_2"></div>
                </div>
                <div className="join__block_3"></div>
                <div className="join__block_4"></div>
                <div className="join__photo_box">
                    <div className="join__photo">
                        <div className="join__photo_mask">
                            <img src={data.photo} alt="joinPhoto" />
                            <div className="join__glare"></div>
                        </div>
                    </div>
                </div>
                <div className="join__block">
                    <h2 className="join__title interText ">
                        <span id="interText__information" ref={ref}></span>
                        <span className="interText__line hidden" id="console-join" ref={nameClass}></span>
                    </h2>
                    <div className="join__description_box">
                        <div className="join__description text">{data.description}</div></div>
                    <div className="join__block_box">
                        <div className="join__photo_mask" ref={exitBlock}>
                            <img src={data.photoSmall} className="join__car" alt="joinPhoto" />
                            <div className="join__glare"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
});