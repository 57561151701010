// React
import React, { useEffect } from 'react';

// Style
import style from '../Page/page.scss';


const Page = ({ data }) => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <div className="page">
                <h1 className="page__title">
                    {data.title}
                </h1>
                <p>
                    {data.p}
                </p>
                <h2>
                    {data.titleh2}
                </h2>
                <p>
                    {data.p}
                </p>
                <ul>
                    <li>
                        {data.item}
                    </li>
                    <li>
                        {data.item}
                    </li>
                    <li>
                        {data.item}
                    </li>
                </ul>
                <p>
                    {data.p}
                </p>
            </div>
        </>
    )
}

export default Page;