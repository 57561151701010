import React, { Component } from "react";
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


export default class SlickSlider extends Component {
    render() {
        const settings = {
            centerMode: true,
            centerPadding: '10px',
            slidesToShow: 3,
            responsive: [
                {
                    breakpoint: 768,
                    settings: {
                        centerMode: true,
                        centerPadding: '40px',
                        slidesToShow: 3,
                        swipe: false,
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        centerMode: true,
                        centerPadding: '55px',
                        swipe: false,
                        slidesToShow: 1
                    }
                }
            ]
        };


        return (
            <div>
                <Slider {...settings}>
                    <div className="slider__block">
                        <div className="progress__block">
                            <div className="block">
                                <div className="block__data">{this.props.carts[0].id} <span>2022</span>
                                </div>
                                <div className="block__steps">
                                    <div className="block__steps-line">
                                        <div className="line-active-1">
                                            <div className="line-point-active-1"></div>
                                            <div className="line-point-active-2"></div>
                                            <div className="line-point-active-3"></div>
                                            <div className="line-point-active-4">
                                            </div>
                                        </div>
                                        <div className="line-point-default-1 line-point-active">
                                        </div>
                                        <div className="line-point-default-2"></div>
                                        <div className="line-point-default-3"></div>
                                        <div className="line-point-default-4"></div>
                                    </div>
                                    <div className="block__step">
                                        <div className="step__name step__name_second">Ideation and Conceptualization</div>
                                    </div><div className="block__step">
                                        <div className="step__name step__name_second">Whitepaper Development and design</div>
                                    </div>
                                    <div className="block__step">
                                        <div className="step__name step__name_second">Team Formation and Recruitment</div>
                                    </div>
                                    <div className="block__step">
                                        <div className="step__name step__name_second">Fundraising and Financing stage</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="slider__block">
                        <div className="progress__block">
                            <div className="block">
                                <div className="block__data">Q2 <span>2022</span>
                                </div>
                                <div className="block__steps">
                                    <div className="block__steps-line">
                                        <div className="line-active-2">
                                            <div className="line-point-active-1"></div>
                                            <div className="line-point-active-2"></div>
                                            <div className="line-point-active-3"></div>
                                            <div className="line-point-active-4">
                                            </div>
                                        </div>
                                        <div className="line-point-default-1 line-point-active">
                                        </div>
                                        <div className="line-point-default-2"></div>
                                        <div className="line-point-default-3"></div>
                                        <div className="line-point-default-4"></div>
                                    </div>
                                    <div className="block__step">
                                        <div className="step__name step__name_second">Minimum Viable Product (MVP)</div>
                                    </div><div className="block__step">
                                        <div className="step__name step__name_second">Community Building and Engagement</div>
                                    </div>
                                    <div className="block__step">
                                        <div className="step__name step__name_second">Testnet Launch and Testing period</div>
                                    </div>
                                    <div className="block__step">
                                        <div className="step__name step__name_second">Initial Security Audit and Review</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="slider__block">
                        <div className="progress__block">
                            <div className="block">
                                <div className="block__data">Q3<span>2022</span>
                                </div>
                                <div className="block__steps">
                                    <div className="block__steps-line">
                                        <div className="line-active-2">
                                            <div className="line-point-active-1"></div>
                                            <div className="line-point-active-2"></div>
                                            <div className="line-point-active-3"></div>
                                            <div className="line-point-active-4">
                                            </div>
                                        </div>
                                        <div className="line-point-default-1 line-point-active">
                                        </div>
                                        <div className="line-point-default-2 line-point-active"></div>
                                        <div className="line-point-default-3"></div>
                                        <div className="line-point-default-4"></div>
                                    </div>
                                    <div className="block__step">
                                        <div className="step__name step__name_second">Mainnet Launch and Deployment</div>
                                    </div><div className="block__step">
                                        <div className="step__name step__name_second">Listing on Cryptocurrency Exchanges</div>
                                    </div>
                                    <div className="block__step">
                                        <div className="step__name step__name_second">Upcoming…</div>
                                    </div>
                                    <div className="block__step">
                                        <div className="step__name step__name_second"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="slider__block">
                        <div className="progress__block">
                            <div className="block">
                                <div className="block__data">Q4 <span>2022</span>
                                </div>
                                <div className="block__steps">
                                    <div className="block__steps-line">
                                        <div className="line-active-1">
                                            <div className="line-point-active-1"></div>
                                            <div className="line-point-active-2"></div>
                                            <div className="line-point-active-3"></div>
                                            <div className="line-point-active-4">
                                            </div>
                                        </div>
                                        <div className="line-point-default-1 line-point-active">
                                        </div>
                                        <div className="line-point-default-2"></div>
                                        <div className="line-point-default-3"></div>
                                        <div className="line-point-default-4"></div>
                                    </div>
                                    <div className="block__step">
                                        <div className="step__name">1 Stategic and Scalable partnership secured</div>
                                    </div><div className="block__step">
                                        <div className="step__name">Open Metaverse Integration</div>
                                    </div>
                                    <div className="block__step">
                                        <div className="step__name">Omnipresent community development</div>
                                    </div>
                                    <div className="block__step">
                                        <div className="step__name">Open Metaverse Integration</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="slider__block">
                        <div className="progress__block">
                            <div className="block">
                                <div className="block__data">Q5 <span>2022</span>
                                </div>
                                <div className="block__steps">
                                    <div className="block__steps-line">
                                        <div className="line-active-1">
                                            <div className="line-point-active-1"></div>
                                            <div className="line-point-active-2"></div>
                                            <div className="line-point-active-3"></div>
                                            <div className="line-point-active-4">
                                            </div>
                                        </div>
                                        <div className="line-point-default-1 line-point-active">
                                        </div>
                                        <div className="line-point-default-2"></div>
                                        <div className="line-point-default-3"></div>
                                        <div className="line-point-default-4"></div>
                                    </div>
                                    <div className="block__step">
                                        <div className="step__name">1 Stategic and Scalable partnership secured</div>
                                    </div><div className="block__step">
                                        <div className="step__name">Open Metaverse Integration</div>
                                    </div>
                                    <div className="block__step">
                                        <div className="step__name">Omnipresent community development</div>
                                    </div>
                                    <div className="block__step">
                                        <div className="step__name">Open Metaverse Integration</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="slider__block">
                        <div className="progress__block">
                            <div className="block">
                                <div className="block__data">Q6 <span>2022</span>
                                </div>
                                <div className="block__steps">
                                    <div className="block__steps-line">
                                        <div className="line-active-1">
                                            <div className="line-point-active-1"></div>
                                            <div className="line-point-active-2"></div>
                                            <div className="line-point-active-3"></div>
                                            <div className="line-point-active-4">
                                            </div>
                                        </div>
                                        <div className="line-point-default-1 line-point-active">
                                        </div>
                                        <div className="line-point-default-2"></div>
                                        <div className="line-point-default-3"></div>
                                        <div className="line-point-default-4"></div>
                                    </div>
                                    <div className="block__step">
                                        <div className="step__name">1 Stategic and Scalable partnership secured</div>
                                    </div><div className="block__step">
                                        <div className="step__name">Open Metaverse Integration</div>
                                    </div>
                                    <div className="block__step">
                                        <div className="step__name">Omnipresent community development</div>
                                    </div>
                                    <div className="block__step">
                                        <div className="step__name">Open Metaverse Integration</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="slider__block">
                        <div className="progress__block">
                            <div className="block">
                                <div className="block__data">Q7<span>2022</span>
                                </div>
                                <div className="block__steps">
                                    <div className="block__steps-line">
                                        <div className="line-active-1">
                                            <div className="line-point-active-1"></div>
                                            <div className="line-point-active-2"></div>
                                            <div className="line-point-active-3"></div>
                                            <div className="line-point-active-4">
                                            </div>
                                        </div>
                                        <div className="line-point-default-1 line-point-active">
                                        </div>
                                        <div className="line-point-default-2"></div>
                                        <div className="line-point-default-3"></div>
                                        <div className="line-point-default-4"></div>
                                    </div>
                                    <div className="block__step">
                                        <div className="step__name">1 Stategic and Scalable partnership secured</div>
                                    </div><div className="block__step">
                                        <div className="step__name">Open Metaverse Integration</div>
                                    </div>
                                    <div className="block__step">
                                        <div className="step__name">Omnipresent community development</div>
                                    </div>
                                    <div className="block__step">
                                        <div className="step__name">Open Metaverse Integration</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="slider__block">
                        <div className="progress__block">
                            <div className="block">
                                <div className="block__data">Q8<span>2022</span>
                                </div>
                                <div className="block__steps">
                                    <div className="block__steps-line">
                                        <div className="line-active-1">
                                            <div className="line-point-active-1"></div>
                                            <div className="line-point-active-2"></div>
                                            <div className="line-point-active-3"></div>
                                            <div className="line-point-active-4">
                                            </div>
                                        </div>
                                        <div className="line-point-default-1 line-point-active">
                                        </div>
                                        <div className="line-point-default-2"></div>
                                        <div className="line-point-default-3"></div>
                                        <div className="line-point-default-4"></div>
                                    </div>
                                    <div className="block__step">
                                        <div className="step__name">1 Stategic and Scalable partnership secured</div>
                                    </div><div className="block__step">
                                        <div className="step__name">Open Metaverse Integration</div>
                                    </div>
                                    <div className="block__step">
                                        <div className="step__name">Omnipresent community development</div>
                                    </div>
                                    <div className="block__step">
                                        <div className="step__name">Open Metaverse Integration</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Slider>
            </div>
        );
    }
}