// React
import React, { useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';

// Gsap
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

// Animate for title
import interText from '../../functions/animatedText';
import blinkingLine from '../../functions/blinkingLine';

// Components
import Article from "./Article/Article";

// Store
import articles from "../../store/articles";
import blog from "../../store/blog";

// Style
import style from '../Blog/blog.scss';

gsap.registerPlugin(ScrollTrigger);

const Blog = () => {

    const entryBlock = useRef(null);
    const exitBlock = useRef(null);
    const ref = useRef(null);
    const nameClass = useRef(null);

    useEffect(() => {
        window.scrollTo(0, 0);
        var tl = gsap.timeline({
            scrollTrigger: {
                trigger: entryBlock.current,
                start: "top 400px",
                once: true,
                onEnter: run => {
                    interText([blog.title], ref);
                    blinkingLine(nameClass.current);
                },
                endTrigger: exitBlock.current,
            }
        });
    }, []);


    return (
        <>
            <div className="blog" ref={entryBlock}>
                <div className="blog__block">
                    <div className="blog__name section-name ">{blog.name}</div>
                    <h1 className="blog__title interText ">
                        <span id="interText__information" ref={ref}></span>
                        <span className="interText__line hidden" id="console-blog" ref={nameClass}></span>
                    </h1>
                    <div className="blog__union">
                        <div className="blog__photo ">
                            <img src={articles[0].photo} alt="" />
                        </div>
                        <div className="blog__block_box" ref={exitBlock}>
                            <div className="blog__data ">
                                {articles[0].data}
                                <div className="blog__data_decor">
                                    minute
                                </div>
                            </div>
                            <Link to={`article${articles[0].link}`}>
                                <h2 className="blog__title ">{articles[0].title}</h2>
                            </Link>
                            <div className="blog__description text ">{articles[0].description}</div>
                            <Link to={`article${articles[0].link}`}
                                className="blog__button read-more ">
                                {articles[0].linkName}
                                <svg width="25" height="26" viewBox="0 0 25 26" fill="none" xmlns="https://www.w3.org/2000/svg">
                                    <g opacity="0.4" filter="url(#filter0_d_0_2650)">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M8.00026 8.60254L10.3013 13.0001L8.00026 17.3977H9.6875L10.838 15.1989H11.4772L12.4999 13.0001L11.4772 10.8013H10.838L9.6875 8.60254H8.00026Z" fill="url(#paint0_linear_0_2650)" />
                                    </g>
                                    <g filter="url(#filter1_d_0_2650)">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M12.5004 8.60254L14.8014 13.0001L12.5004 17.3977H14.1876L15.3382 15.1989H15.9773L17 13.0001L15.9773 10.8013H15.3382L14.1876 8.60254H12.5004Z" fill="url(#paint1_linear_0_2650)" />
                                    </g>
                                    <defs>
                                        <filter id="filter0_d_0_2650" x="0.000244141" y="0.602539" width="20.4996" height="24.7951" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                            <feFlood floodOpacity="0" result="BackgroundImageFix" />
                                            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                            <feOffset />
                                            <feGaussianBlur stdDeviation="4" />
                                            <feColorMatrix type="matrix" values="0 0 0 0 0.533333 0 0 0 0 0.458824 0 0 0 0 0.984314 0 0 0 0.5 0" />
                                            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_0_2650" />
                                            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_0_2650" result="shape" />
                                        </filter>
                                        <filter id="filter1_d_0_2650" x="4.50037" y="0.602539" width="20.4996" height="24.7951" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                            <feFlood floodOpacity="0" result="BackgroundImageFix" />
                                            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                            <feOffset />
                                            <feGaussianBlur stdDeviation="4" />
                                            <feColorMatrix type="matrix" values="0 0 0 0 0.533333 0 0 0 0 0.458824 0 0 0 0 0.984314 0 0 0 0.5 0" />
                                            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_0_2650" />
                                            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_0_2650" result="shape" />
                                        </filter>
                                        <linearGradient id="paint0_linear_0_2650" x1="3.70496" y1="13.0001" x2="13.0498" y2="13.0001" gradientUnits="userSpaceOnUse">
                                            <stop stopColor="#FC64FC" />
                                        </linearGradient>
                                        <linearGradient id="paint1_linear_0_2650" x1="8.20508" y1="13.0001" x2="17.5499" y2="13.0001" gradientUnits="userSpaceOnUse">
                                            <stop stopColor="#FC64FC" />
                                        </linearGradient>
                                    </defs>
                                </svg>
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="blog__name_section ">
                    {blog.titlePosts}
                </div>
                <div className="blog__articles">
                    {articles.slice(1).map((article) => (
                        <div className="blog__article " key={article.id}>
                            <Article data={article} />
                        </div>
                    ))}
                </div>
            </div>
        </>
    )
}

export default Blog;