const repeatInformation = {
    logo: 'https://preview.vb.in.ua/rentalz/img/logo.svg',
    linkLogo: '/',
    copyright: 'All content Senegts ©',
    button1: 'Mint',
    button1Link: '/4',
    button2: 'Stake',
    button2Link: '/5',
    tuchLogo: 'https://preview.vb.in.ua/rentalz/img/tuchLogo.svg',
    tuchTitle: 'Be a part of greatness join us today!',
    tuchVideo: 'https://preview.vb.in.ua/rentalz/img/tuchVideo-2.mp4',
    tuchButton: 'Get started',
    tuchButtonLink: '/8',
    descriptionFooter: 'The only NFT platform where you can tap into a world of high-end luxury cars and real estate.',
    labelName1: 'Newletter',
    labelEmail1: '',
    labelName2: 'Corporate updates',
    labelEmail2: '',
    buttonLabel: 'Subscribe',
}

export { repeatInformation }; 