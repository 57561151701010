// React
import React from 'react';
import { Link } from "react-router-dom";

// Style
import style from './buttonProductConnect.scss';


function ButtonProductConnect({ text, link, }) {
    return (
        <Link className="buttonProductConnect" to={{ pathname: link }}><span>{text}</span></Link>
    )
}

export default ButtonProductConnect;