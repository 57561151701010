const networkContacts = {
    name1: 'Email',
    name2: 'Social media',
    mail: 'rentalzio@gmail.com'

}

const formContacts = {
    title: 'DROP US A LINE',
    agreement: 'AGREE WITH THE',
    agreementNameLink: 'PRIVACY POLICY',
    agreementLink: '/111',
    submitName: 'Submit form'
}

export { networkContacts, formContacts }