// React
import React, { useRef, useEffect, useState } from 'react';

// Gsap
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

// Components
import ButtonOffer from "../../../../components/buttons/ButtonOffer/ButtonOffer";

// Animate for title
import interText from '../../../../functions/animatedText';
import blinkingLine from '../../../../functions/blinkingLine';

// Style
import style from "../OfferHome/offerHome.scss";


export default React.memo(function OfferHome({ data }) {

    const entryBlock = useRef(null);
    const exitBlock = useRef(null);
    const entryBlockSecond = useRef(null);
    const exitBlockSecond = useRef(null);
    const refSecond = useRef(null);
    const nameClass = useRef(null);

    const [showButton, setShowButton] = useState(false);
    const [startAnimation, setStartAnimation] = useState(true);

    useEffect(() => {
        if (startAnimation) {
            var tl = gsap.timeline({
                scrollTrigger: {
                    trigger: entryBlock.current,
                    start: "top 400px",
                    once: true,
                    onEnter: run => {
                        setTimeout(() => {
                            interText([data.titleDecor], refSecond);
                            blinkingLine(nameClass.current);
                        }, 700);

                        setTimeout(() => {
                            setShowButton(true);
                            gsap.fromTo(".offer__button_active",
                                {
                                    y: 20,
                                    opacity: 0,
                                },
                                {
                                    y: 0,
                                    duration: 1.5,
                                    ease: "power4.out",
                                    opacity: 1,
                                })
                        }, 500);

                        if (document.documentElement.clientWidth < 420) {
                            gsap.fromTo(".offer__back",
                                {
                                    scale: 1,
                                    opacity: 0,
                                },
                                {
                                    scale: 1,
                                    duration: 2.5,
                                    ease: "expo.out",
                                    opacity: 1,
                                })

                            gsap.fromTo(".offer__title",
                                {},
                                {
                                    duration: 3.5,
                                    opacity: 1
                                })
                        } else {
                            gsap.fromTo(".offer__back",
                                {
                                    scale: 1.5000005,
                                    opacity: 0,
                                },
                                {
                                    scale: 1,
                                    duration: 2.5,
                                    ease: "expo.out",
                                    opacity: 1,
                                })

                            gsap.fromTo(".offer__union",
                                {
                                    scale: 1.5000005,
                                    opacity: 0,
                                },
                                {
                                    scale: 1,
                                    duration: 2.5,
                                    ease: "expo.out",
                                    opacity: 1,
                                })


                            gsap.fromTo(".offer__title",
                                {},
                                {
                                    duration: 3.5,
                                    opacity: 1
                                })
                        }
                    },
                    endTrigger: exitBlock.current,
                }
            });

            if (document.documentElement.clientWidth < 420) {
                var tl = gsap.timeline({
                    scrollTrigger: {
                        trigger: '.offer__block',
                        start: "top 100px",
                        end: "top top",
                        scrub: true,
                        endTrigger: exitBlockSecond.current,
                    }
                });
                tl.to('.offer__back-color', {
                    opacity: 1,
                    duration: 0.5,
                    display: 'block'
                })

                var tl = gsap.timeline({
                    scrollTrigger: {
                        trigger: '.offer__back-color',
                        start: "top 0px",
                        end: "top top",
                        scrub: true,
                        endTrigger: exitBlockSecond.current,
                    }
                });
                tl.to('.offer', {
                    scale: 1,
                })

                setTimeout(() => {
                    gsap.fromTo(".offer__button_arrow",
                        {
                            opacity: 0,
                        },
                        {
                            duration: 2.5,
                            ease: "expo.out",
                            opacity: 1,
                        })
                }, 1500);
            } else {
                var tl = gsap.timeline({
                    scrollTrigger: {
                        trigger: '.offer__block',
                        start: "top 100px",
                        end: "top top",
                        scrub: true,
                        endTrigger: exitBlockSecond.current,
                    }
                });
                tl.to('.offer__back-color', {
                    opacity: 0.8,
                    duration: 0.5,
                    display: 'block'
                })

                var tl = gsap.timeline({
                    scrollTrigger: {
                        trigger: '.offer__back-color',
                        start: "top 0px",
                        end: "top top",
                        scrub: true,
                        endTrigger: exitBlockSecond.current,
                    }
                });
                tl.to('.offer', {
                    scale: 1,
                })
            }
            setStartAnimation(false)
        }
    }, []);


    return (
        <div className="offer">
            <div className="offer__back"></div>
            <div className="offer__back-color"></div>
            <div className="offer__union">
                <div className="offer__block">
                    <h1 className="offer__title interText" ref={entryBlockSecond}>
                        {data.title}
                    </h1>
                    <div className="offer__title_mark styleText interText">
                        <span id="interText__information" ref={refSecond}></span>
                        <span className="interText__line hidden" id="console-offerSecond" ref={nameClass}></span> </div>
                    <div className="offer__button_active">
                        <div className={showButton ? 'offer__button_active ' : 'offer__button hideBlock'}>
                            <ButtonOffer text={data.button} link={data.link} decor={true} isPopup={data.link ? true : false} />
                        </div>
                    </div>
                    <div className="offer__button_arrow"></div>
                </div>
            </div>
            <div className="offer__exit" ref={exitBlockSecond}></div>
        </div>
    )
}); 