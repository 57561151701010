// React
import React from "react";
import { Route, Routes } from "react-router-dom";

// Components
import { Layout } from "../Layout/Layout";
import Home from "../../pages/Home/Home";
import About from "../../pages/About/About";
import TeamPage from "../../pages/Team/TeamPage";
import Faq from "../../pages/Faq/Faq";
import Blog from "../../pages/Blog/Blog";
import Article from "../../components/Templates/ArticlePage/ArticlePage";
import PrivacyPolicy from "../../pages/PrivacyPolicy/PrivacyPolicy";
import ProductPage from "../../components/Templates/ProductPage/ProductPage";
import TestPage from "../../pages/TestPage/TestPage";
import Contacts from "../../pages/Contacts/Contacts";
import NotFound from "../../pages/NotFound/NotFound";

export const BasicContainer = () => {
    return (
        <>
            <Routes>
                <Route path="/" element={<Layout />}>
                    <Route index element={<Home />} />
                    <Route path={'about'} element={<About />} />
                    <Route path={'team'} element={<TeamPage />} />
                    <Route path={'faq'} element={<Faq />} />
                    <Route path={'blog'} element={<Blog />} />
                    <Route path={'blog/article/:id'} element={<Article />} />
                    <Route path={'products/nft/:id'} element={<ProductPage />} />
                    <Route path={'privacy-policy'} element={<PrivacyPolicy />} />
                    <Route path={'test'} element={<TestPage />} />
                    <Route path={'contacts'} element={<Contacts />} />
                    <Route path="*" element={<NotFound />} />
                </Route>
            </Routes>
        </ >
    )
}
