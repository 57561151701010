// React
import React, { useRef, useEffect, useState } from 'react';

// Gsap
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

// Components
import Button from "../../../../components/buttons/ButtonDecor/ButtonDecor";

// Animate for title
import interText from '../../../../functions/animatedText';
import blinkingLine from '../../../../functions/blinkingLine';

// Style
import style from '../About/about.scss';


const AboutSection = ({ data }) => {

    const entryBlock = useRef(null);
    const exitBlock = useRef(null);
    const ref = useRef(null);
    const nameClass = useRef(null);

    const [startAnimation, setStartAnimation] = useState(true);

    useEffect(() => {
        if (startAnimation) {
            var tl = gsap.timeline({
                scrollTrigger: {
                    trigger: entryBlock.current,
                    start: "top 400px",
                    once: true,
                    onEnter: run => {
                        interText([data.title], ref);
                        blinkingLine(nameClass.current);

                        setTimeout(() => {
                            gsap.fromTo(".block__name", { y: 20, opacity: 0, }, { y: 0, duration: 1.5, ease: "power4.out", opacity: 1, })
                        }, 100);

                        if (document.documentElement.clientWidth < 420) {
                            setTimeout(() => {
                                gsap.fromTo(".slider-aboutSection", { opacity: 0, y: -50, }, { y: 0, duration: 2.5, ease: "power4.out", opacity: 1, })
                            }, 1000);
                        } else {
                            setTimeout(() => {
                                gsap.fromTo(".slider-aboutSection", { opacity: 0, y: -50, }, { y: 0, duration: 2.5, ease: "power4.out", opacity: 1, })
                            }, 100);
                        }

                        setTimeout(() => {
                            gsap.fromTo(".block__description", { y: 30, opacity: 0, }, { y: 0, duration: 2.5, ease: "power4.out", opacity: 1, })
                        }, 700);

                        setTimeout(() => {
                            gsap.fromTo(".block__button", { y: 30, opacity: 0, }, { y: 0, duration: 2.5, ease: "power4.out", opacity: 1, })
                        }, 900);
                    },
                    toggleClass: {
                        targets: ".slider__photo_left",
                        className: "slider__photo_left_active"
                    },
                    endTrigger: exitBlock.current
                }
            });
            setStartAnimation(false);
        }
    }, []);

    useEffect(() => {
        var tl = gsap.timeline({
            scrollTrigger: {
                trigger: entryBlock.current,
                start: "top 400px",
                once: true,
                toggleClass: {
                    targets: ".slider__photo_right",
                    className: "slider__photo_right_active"
                },
                endTrigger: exitBlock.current
            }
        });
    }, []);


    return (
        <div className="aboutSection" ref={entryBlock}>
            <div className="aboutSection__union">
                <div className="aboutSection__slider ">
                    <div className="slider__photos slider-aboutSection">
                        <div className="slider__photo slider__photo_left">
                            <img src={data.photo_1} alt="sliderPhoto" />
                        </div>
                        <div className="slider__photo slider__photo_main">
                            <div className="slider__photo_main_mask">
                                <img src={data.photo_2} alt="sliderPhoto" />
                            </div>
                        </div>
                        <div className="slider__photo_right_box">
                            <div className="slider__photo slider__photo_right">
                                <img src={data.photo_3} alt="sliderPhoto" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="aboutSection__block" ref={exitBlock}>
                    <div className="block">
                        <div className="block__name section-name ">{data.name}</div>
                        <h2 className="block__title interText ">
                            <span id="interText__information" ref={ref}></span>
                            <span className="interText__line hidden" id="console-aboutSection" ref={nameClass}></span>
                        </h2>
                        <div className="block__description text ">
                            {data.description}
                        </div>
                        <div className="block__button ">
                            <Button text={data.button} link={data.link} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AboutSection;