const blog =
{
    name: 'MEET THE',
    title: 'OUR BLOG_',
    titlePosts: 'NEW ARTICLES',
    titleSame: 'OUR BLOG_',
    titleMoreArticle: 'More articles_',
    allPostButton: 'To all articles',
    buttonSocial: 'Share',
    buttonMoreArticle: 'View all',
    linkAll: '/blog'
};

export default blog;