// React
import React, { useState, useRef } from 'react';
import { Link } from "react-router-dom";

// Gsap
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

// Components
import ButtonDefaultHeader from '../buttons/header/ButtonDefaultHeader/ButtonDefaultHeader';
import ButtonMainHeader from '../buttons/header/ButtonMainHeader/ButtonMainHeader';
import Socials from "../Socials/Socials";

// Style
import style from '../Header/header.scss';


const Header = ({ data, content }) => {
    const menuLink = useRef();

    const [startAnimation, setStartAnimation] = useState(true);

    const menuLinkBlock = menuLink.current;
    const html = document.querySelector(["html"]);
    const items = document.querySelectorAll([".header .menu-element"]);
    let startAnimateItem;

    const showMainMenu = () => {
        gsap.fromTo(".header__menu_box", {}, { duration: 0.5, opacity: 1 });
        menuLinkBlock.style.display = 'block';
        html.style.overflow = 'hidden';

        function showItem() {
            let nextNumber = 0;
            let countNumber = items.length;

            startAnimateItem = setInterval(() => {
                gsap.fromTo(items[nextNumber], { opacity: 0, }, { duration: 1.5, ease: "power4.out", opacity: 1, });

                nextNumber++;

                if (nextNumber === countNumber) {
                    clearTimeout(startAnimateItem);
                }
            }, 100);
        }
        showItem();
    }

    const closeMainMenu = () => {
        items.forEach((item) => {
            item.style.opacity = 0;
        });

        setTimeout(() => {
            items.forEach((item) => {
                item.style.opacity = 0;
            });
        }, 800);

        clearTimeout(startAnimateItem);

        gsap.fromTo(".header__menu_box", { opacity: 1 }, { duration: 0.5, opacity: 0 });
        setTimeout(() => {
            menuLinkBlock.style.display = 'none';
        }, 500);
        html.style.overflow = 'auto';
    }

    const chooseItem = () => {
        gsap.fromTo(".header__menu_box", { y: 50, opacity: 1 }, { y: 0, duration: 0.5, opacity: 0 });
        setTimeout(() => {
            menuLinkBlock.style.display = 'none';
        }, 500);
        html.style.overflow = 'auto';
    }

    setTimeout(() => {
        if (startAnimation) {
            gsap.fromTo(".header", {}, { y: 0, duration: 2.8, opacity: 1 });
            setStartAnimation(false);
        }
    }, 400);

    if (document.documentElement.clientWidth < 420) {

        gsap.fromTo(".menu__button", {}, { duration: 1.8, opacity: 1 });

    } else {
        setTimeout(() => {
            gsap.fromTo(".menu__button", {}, { duration: 2.8, opacity: 1 });
        }, 1500);
    }


    return (
        <header className="header">
            <div className="header__box">
                <div className="header__menu">
                    <div className="menu">
                        <div className="menu__button" onClick={showMainMenu}>
                            <span className="menu__line_first"></span>
                            <span className="menu__line_second"></span>
                        </div>
                        <ul>
                            {data.menuFirst.map((item) => (

                                <li key={item.id}>
                                    <Link to={item.link}>
                                        {item.name}
                                    </Link>
                                </li>

                            ))}
                        </ul>
                    </div>
                </div>
                <div className="header__menu_box" ref={menuLink}>
                    <div className="menu_box">
                        <div className="menu">
                            <div className="menu__header">
                                <div className="menu__close" onClick={() => { closeMainMenu() }}> <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1 1L20.9999 20.9999" stroke="white" />
                                    <path d="M1 21L20.9999 1.00006" stroke="white" />
                                </svg>
                                    Close</div>
                                <div className="menu__logo">
                                    <Link to={content.linkLogo}>
                                        <img src={content.logo} alt="Logo" />
                                    </Link>
                                </div>
                                <div className="menu__buttons">
                                    <div className="menu__button">
                                        <ButtonDefaultHeader text={content.button1} link={content.button2Link} />
                                    </div>
                                    <div className="menu__button">
                                        <ButtonMainHeader text={content.button2} link={content.button1Link} />
                                    </div>
                                </div>
                            </div>
                            <div className="menu__body">
                                <ul className="menu__links">
                                    {data.menuSecond.slice(0, 1).map((item) => (
                                        <li className={`menu__link menu__link_${item.id} menu-element`} key={item.id} onClick={() => { chooseItem() }}>
                                            <Link to={item.link}>
                                                <div className="menu__link_active">
                                                    <div className="menu__link_box">
                                                        <div className="menu__marquee-container">
                                                            <div className="menu__marquee">
                                                                <div className="box">
                                                                    <div className="box__title box__title_full">
                                                                        {item.name}
                                                                    </div>
                                                                    <div className="box__img">
                                                                        <img src={item.ico} alt={item.name} />
                                                                    </div>
                                                                </div>
                                                                <div className="box">
                                                                    <div className="box__title box__title_full">
                                                                        {item.name}
                                                                    </div>
                                                                    <div className="box__img">
                                                                        <img src={item.ico} alt={item.name} />
                                                                    </div>
                                                                </div>
                                                                <div className="box">
                                                                    <div className="box__title box__title_full">
                                                                        {item.name}
                                                                    </div>
                                                                    <div className="box__img">
                                                                        <img src={item.ico} alt={item.name} />
                                                                    </div>
                                                                </div>
                                                                <div className="box">
                                                                    <div className="box__title box__title_full">
                                                                        {item.name}
                                                                    </div>
                                                                    <div className="box__img">
                                                                        <img src={item.ico} alt={item.name} />
                                                                    </div>
                                                                </div>
                                                                <div className="box">
                                                                    <div className="box__title box__title_full">
                                                                        {item.name}
                                                                    </div>
                                                                    <div className="box__img">
                                                                        <img src={item.ico} alt={item.name} />
                                                                    </div>
                                                                </div>
                                                                <div className="box">
                                                                    <div className="box__title box__title_full">
                                                                        {item.name}
                                                                    </div>
                                                                    <div className="box__img">
                                                                        <img src={item.ico} alt={item.name} />
                                                                    </div>
                                                                </div>
                                                                <div className="box">
                                                                    <div className="box__title box__title_full">
                                                                        {item.name}
                                                                    </div>
                                                                    <div className="box__img">
                                                                        <img src={item.ico} alt={item.name} />
                                                                    </div>
                                                                </div>
                                                                <div className="box">
                                                                    <div className="box__title box__title_full">
                                                                        {item.name}
                                                                    </div>
                                                                    <div className="box__img">
                                                                        <img src={item.ico} alt={item.name} />
                                                                    </div>
                                                                </div>
                                                                <div className="box">
                                                                    <div className="box__title box__title_full">
                                                                        {item.name}
                                                                    </div>
                                                                    <div className="box__img">
                                                                        <img src={item.ico} alt={item.name} />
                                                                    </div>
                                                                </div>
                                                                <div className="box">
                                                                    <div className="box__title box__title_full">
                                                                        {item.name}
                                                                    </div>
                                                                    <div className="box__img">
                                                                        <img src={item.ico} alt={item.name} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="menu__link_default">
                                                    {item.name}
                                                </div>
                                            </Link>
                                        </li>
                                    ))}
                                    {data.menuSecond.slice(1, 7).map((item) => (
                                        <li className={`menu__link menu__link_${item.id} menu-element`} key={item.id} onClick={() => { chooseItem() }}>
                                            <Link to={item.link}>
                                                <div className="menu__link_active">
                                                    <div className="menu__link_box">
                                                        <div className="menu__marquee-container">
                                                            <p className="menu__marquee">
                                                                <span> {item.name}</span>
                                                                <span> {item.name}</span>
                                                                <span> {item.name}</span>
                                                                <span> {item.name}</span>
                                                                <span> {item.name}</span>
                                                                <span> {item.name}</span>
                                                                <span> {item.name}</span>
                                                                <span> {item.name}</span>
                                                                <span> {item.name}</span>
                                                                <span> {item.name}</span>
                                                                <span> {item.name}</span>
                                                                <span> {item.name}</span>
                                                                <span> {item.name}</span><span> {item.name}</span>
                                                                <span> {item.name}</span>
                                                                <span> {item.name}</span>
                                                                <span> {item.name}</span>
                                                                <span> {item.name}</span>
                                                                <span> {item.name}</span>
                                                                <span> {item.name}</span>
                                                                <span> {item.name}</span>
                                                                <span> {item.name}</span>
                                                                <span> {item.name}</span>
                                                                <span> {item.name}</span>
                                                                <span> {item.name}</span>
                                                                <span> {item.name}</span><span> {item.name}</span>
                                                                <span> {item.name}</span>
                                                                <span> {item.name}</span>
                                                                <span> {item.name}</span>
                                                                <span> {item.name}</span>
                                                                <span> {item.name}</span>
                                                                <span> {item.name}</span>
                                                                <span> {item.name}</span>
                                                                <span> {item.name}</span>
                                                                <span> {item.name}</span>
                                                                <span> {item.name}</span>
                                                                <span> {item.name}</span>
                                                                <span> {item.name}</span>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="menu__link_default">
                                                    {item.name}
                                                </div>
                                            </Link>
                                        </li>
                                    ))}
                                </ul>
                                <div className="menu__socials menu-element">
                                    <Socials />
                                </div>
                                <div className="menu__popularlinks">
                                    <div className="links">
                                        <span className="link copyright menu-element">
                                            {content.copyright}
                                        </span>
                                        {data.menuThird.map((item) => (
                                            <li key={item.id}>
                                                <Link className="link menu-element" to={item.link}>
                                                    {item.name}
                                                </Link>
                                            </li>
                                        ))}
                                        <span className="link designed menu-element"><Link to="/">
                                            Designed by:
                                            <span className="company">Intex</span></Link></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="header__logo">
                <Link to={content.linkLogo}>
                    <img src={content.logo} alt="Logo" />
                </Link>
            </div>
            <div className="header__buttons">
                <div className="header__button">
                    <ButtonDefaultHeader text={content.button1} link={content.button1Link} />
                </div>
                <div className="header__button">
                    <ButtonMainHeader text={content.button2} link={content.button2Link} />
                </div>
            </div>
        </header >
    )
}

export default Header;