// React
import React from 'react';

// Components
import OfferHome from "./components/OfferHome/OfferHome";
import Join from "./components/Join/Join";
import Statistics from "./components/Statistics/Statistics";
import Video from "./components/Video/Video";
import Benefits from "./components/Benefits/Benefits";
import HowItWorks from "./components/HowItWorks/HowItWorks";
import Collection from "./components/Collection/Collection";
import Progress from "./components/Progress/Progress";
import Faq from "./components/Faq/FaqSection";

// Store
import { dataFaq } from "../../store/faq";
import { offerHome, joinHome, statisticsHome, videoHome, benefitsHome, howItWorksHome, collectionHome, progressHome } from "../../store/home";

// Style
import style from './home.scss';


function Home() {
    return (
        <>
            <OfferHome data={offerHome} />
            <Join data={joinHome} />
            <Statistics data={statisticsHome} />
            <Video data={videoHome} />
            <Benefits data={benefitsHome} />
            <HowItWorks data={howItWorksHome} />
            <Collection data={collectionHome} />
            <Progress data={progressHome} />
            <Faq data={dataFaq} />
        </>
    )
}

export default Home