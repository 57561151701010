// React
import React, { useState, useRef, useEffect } from 'react';
import { Link } from "react-router-dom";

// Gsap
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

// Select
import Select from 'react-select';

// Components
import ButtonMainSend from "../../../../components/buttons/ButtonMainSend/ButtonMainSend";

// Animate for title
import interText from '../../../../functions/animatedText';
import blinkingLine from '../../../../functions/blinkingLine';

// Style
import style from '../Form/form.scss';


function Form({ data }) {

    const entryBlock = useRef(null);
    const exitBlock = useRef(null);
    const ref = useRef(null);
    const nameClass = useRef(null);

    const [value1, setValue1] = useState('');
    const [value2, setValue2] = useState('');
    const [value3, setValue3] = useState('');
    const [value4, setValue4] = useState('');
    const [value5, setValue5] = useState('');
    const [value6, setValue6] = useState('');
    const [value7, setValue7] = useState('');

    const [activeSelect, setActiveSelect] = useState(false);

    useEffect(() => {
        var tl = gsap.timeline({
            scrollTrigger: {
                trigger: entryBlock.current,
                start: "top 500px",
                once: true,
                onEnter: run => {
                    interText([data.title], ref);
                    blinkingLine(nameClass.current);
                },
                endTrigger: exitBlock.current,
                end: "bottom 50%+=100px",
            }
        });
    }, []);

    const choseItem = () => {
        let inputBlock = document.querySelector([".form .form__box form .form_select_box .select__value"]);

        setActiveSelect(true);
        inputBlock.classList.add('select__value_active');
    }

    const chosenItem = (e) => {
        let inputBlock = document.querySelector([".form .form__box form .form_select_box .select__value"]);

        let choseItem = e.target.textContent;
        inputBlock.innerHTML = choseItem;
        inputBlock.classList.remove('select__value_active');
        setActiveSelect(false);
    }

    const arrQuestions = [
        {
            name: 'Collaboration',
        },
        {
            name: 'I have a quastions',
        },
        {
            name: 'General inquiry',
        }
    ]

    return (
        <>
            <div className="form" ref={entryBlock}>
                <h1 className="form__title interText"><span id="interText__information" ref={ref}></span>
                    <span className="interText__line hidden" id="console-form" ref={nameClass}></span></h1>
                <div className="form__box" ref={exitBlock}>
                    <form action="#">
                        <div className="form_select_box">
                            <div className="select__value" onClick={() => choseItem()}>
                                Choose answer
                            </div>
                            {activeSelect && <div className="select">
                                <input type="text" className="select__input" />
                                <ul className="select__items">
                                    {
                                        arrQuestions.map((item, id) => (
                                            <li className="select_item" onClick={(e) => chosenItem(e)} key={id}>
                                                {item.name}
                                            </li>
                                        ))
                                    }
                                </ul>
                            </div>}
                        </div>
                        <label htmlFor="">
                            {value1 && <span className="form__name">Full name <span className="form__star">*</span></span>}
                            <input type="text" name="name" placeholder="Full name" className="form__input" value={value1} onChange={event => setValue1(event.target.value)} />
                        </label>
                        <label htmlFor="">
                            {value2 && <span className="form__name">Phone number <span className="form__star">*</span></span>}
                            <input type="text" name="phone" className="form__input" placeholder="Phone number" value={value2} onChange={event => setValue2(event.target.value)} />
                        </label>
                        <label htmlFor="">
                            {value3 && <span className="form__name">Website</span>}
                            <input type="text" name="website" placeholder="Website" className="form__input" value={value3} onChange={event => setValue3(event.target.value)} />
                        </label>
                        <label htmlFor="">
                            {value4 && <span className="form__name">Company</span>}
                            <input type="text" name="company" placeholder="Company" className="form__input" value={value4} onChange={event => setValue4(event.target.value)} />
                        </label>
                        <label htmlFor="">
                            {value5 && <span className="form__name">Email <span className="form__star">*</span></span>}
                            <input type="text" name="email" placeholder="email" className="form__input" value={value5} onChange={event => setValue5(event.target.value)} />
                        </label>
                        <label htmlFor="">
                            {value6 && <span className="form__name">Country</span>}
                            <input type="text" name="country" placeholder="Country" className="form__input" value={value6} onChange={event => setValue6(event.target.value)} />
                        </label>
                        <label htmlFor="">
                            {value7 && <span className="form__name">Comment</span>}
                            <textarea name="comment" className="form__text" cols="30" placeholder="Comment" rows="10" value={value7} onChange={event => setValue7(event.target.value)}></textarea>
                        </label>
                        <div className="agreement">
                            <label className="checkbox">
                                <input className="checkbox__default" type="checkbox" />
                                <span className="checkbox__new"></span>
                                <span className="checkbox__descr">{data.agreement}</span>
                            </label>
                            <Link to={data.agreementLink}>{data.agreementNameLink}</Link></div>
                        <ButtonMainSend text={data.submitName} />
                    </form>
                </div>
            </div>
        </>
    )
}
export default Form;