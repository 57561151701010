// React
import React, { Component } from "react";

// Slider
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// Components
import CarouselBlock from "./CarouselBlock/CarouselBlock";


export default class HowItWorksSlider extends Component {

    render() {
        const settings = {
            centerMode: true,
            centerPadding: '10px',
            slidesToShow: 3,
            responsive: [
                {
                    breakpoint: 770,
                    settings: {
                        centerMode: true,
                        centerPadding: '45px',
                        slidesToShow: 2,
                        swipe: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        centerMode: true,
                        centerPadding: '45px',
                        swipe: true,
                        slidesToShow: 1,
                        dots: true
                    }
                }
            ]
        };

        return (
            <div>
                <Slider {...settings}>
                    {
                        this.props.data.map((items, id) => (
                            <CarouselBlock item={items} key={id} />
                        ))
                    }
                </Slider>
            </div>
        );
    }
}
