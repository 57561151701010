// React
import React, { useRef, useEffect, useState } from 'react';

// Gsap
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

// Animate title
import interText from '../../../../functions/animatedText';
import blinkingLine from '../../../../functions/blinkingLine';

// Style
import style from "../Benefits/benefits.scss";


export default React.memo(function Benefits({ data }) {
    const entryBlock = useRef(null);
    const exitBlock = useRef(null);
    const entryBlockSecond = useRef(null);
    const ref = useRef(null);
    const nameClass = useRef(null);

    const [startAnimation, setStartAnimation] = useState(true);

    useEffect(() => {
        if (startAnimation) {
            var tl = gsap.timeline({
                scrollTrigger: {
                    trigger: entryBlock.current,
                    start: "top 600px",
                    once: true,
                    endTrigger: exitBlock.current,
                    onEnter: run => {
                        interText([data.title], ref);
                        blinkingLine(nameClass.current);
                    },
                }
            });

            var tl = gsap.timeline({
                scrollTrigger: {
                    trigger: entryBlockSecond.current,
                    start: "top 600px",
                    once: true,
                    endTrigger: exitBlock.current,
                    onEnter: run => {

                        gsap.fromTo(".benefits__union_box-shadow", { scale: 0, opacity: 0, }, { scale: 1, duration: 2.5, ease: "expo.out", opacity: 1, })

                        setTimeout(() => {
                            gsap.fromTo(".benefits__ico", { y: 20, opacity: 0, }, { y: 0, duration: 1.5, ease: "power4.out", opacity: 1, })
                        }, 100);

                        setTimeout(() => {
                            gsap.fromTo(".benefits__name", { y: 20, opacity: 0, }, { y: 0, duration: 1.5, ease: "power4.out", opacity: 1, })
                        }, 200);

                        setTimeout(() => {
                            gsap.fromTo(".benefits__description", { y: 20, opacity: 0, }, { y: 0, duration: 1.5, ease: "power4.out", opacity: 1, })
                        }, 300);
                    },
                }
            });

            var tl = gsap.timeline({
                scrollTrigger: {
                    trigger: '.benefits__union_box',
                    start: "top 300px",
                    end: "top top",
                    scrub: true,
                    endTrigger: exitBlock.current,
                }
            });
            tl.fromTo('.benefits__photo_mask img',
                {
                    scale: 1.5,
                },
                {
                    scale: 1,
                    duration: 0.5,
                    ease: "power4.out",
                })
            setStartAnimation(false);
        }
    }, []);


    return (
        <div className="benefits" ref={entryBlock}>
            <div className="benefits__block">
                <h2 className="benefits__title interText benefits__title_about">
                    <span id="interText__information" ref={ref}></span>
                    <span className="interText__line hidden" id="console-benefits" ref={nameClass}></span>
                </h2>
                <div className="benefits__video">
                    <img src={data.icoSecond} alt="benefitsPhoto" />
                </div>
            </div>
            <div className="benefits__union_box">
                <div className="benefits__union_box-shadow"></div>
                <div className="benefits__union" ref={entryBlockSecond}>
                    <div className="benefits__photo_mask">
                        <img src={data.ico} alt="benefitsPhoto" />
                    </div>
                    {
                        data.items.map((item) => (
                            <div className="benefits__box" key={item.id}>
                                <div className="benefits__box_mask">
                                    <img src={data.ico} alt="benefitsPhoto" />
                                </div>
                                <img src={item.ico} className="benefits__ico" alt="benefitsIco" />
                                <div className="benefits__name">{item.title}</div>
                                <div className="benefits__description text">{item.description}</div>
                            </div>
                        ))
                    }
                </div>
            </div>
            <div className="benefits__exit" ref={exitBlock}></div>
        </div>
    )
});