// React
import React, { useRef, useEffect, useState } from 'react';

// Gsap
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

// Animate for title
import interText from '../../../../functions/animatedText';
import blinkingLine from '../../../../functions/blinkingLine';

// Style
import style from '../Offer/offer.scss';

gsap.registerPlugin(ScrollTrigger);


const Offer = ({ data }) => {

    const entryBlock = useRef(null);
    const exitBlock = useRef(null);
    const ref = useRef(null);
    const nameClass = useRef(null);

    const [startAnimation, setStartAnimation] = useState(true);

    useEffect(() => {
        if (startAnimation) {
            if (document.documentElement.clientWidth < 420) {
                var tl = gsap.timeline({
                    scrollTrigger: {
                        trigger: entryBlock.current,
                        start: "top 500px",
                        once: true,
                        onEnter: run => {
                            interText([data.title], ref);
                            blinkingLine(nameClass.current);

                            gsap.fromTo(".box__name",
                                {
                                    y: 30,
                                    opacity: 0
                                },
                                {
                                    y: 0,
                                    duration: 2.5,
                                    ease: "power4.out",
                                    opacity: 1,
                                });

                            setTimeout(() => {
                                gsap.fromTo(".box__description",
                                    {
                                        y: 30,
                                        opacity: 0
                                    },
                                    {
                                        y: 0,
                                        duration: 2.5,
                                        ease: "power4.out",
                                        opacity: 1,
                                    });
                            }, 200);
                        },
                        endTrigger: exitBlock.current,
                    }
                });
            } else {
                var tl = gsap.timeline({
                    scrollTrigger: {
                        trigger: entryBlock.current,
                        start: "top 400px",
                        once: true,
                        onEnter: run => {
                            interText([data.title], ref);
                            blinkingLine(nameClass.current);

                            gsap.fromTo(".box__name",
                                {
                                    y: 30,
                                    opacity: 0
                                },
                                {
                                    y: 0,
                                    duration: 2.5,
                                    ease: "power4.out",
                                    opacity: 1,
                                });

                            setTimeout(() => {
                                gsap.fromTo(".box__description",
                                    {
                                        y: 30,
                                        opacity: 0
                                    },
                                    {
                                        y: 0,
                                        duration: 2.5,
                                        ease: "power4.out",
                                        opacity: 1,
                                    });
                            }, 200);

                            if (document.documentElement.clientWidth < 420) {
                                setTimeout(() => {
                                    gsap.fromTo(".offerAbout__slider",
                                        {
                                            y: 30,
                                            opacity: 0
                                        },
                                        {
                                            y: 0,
                                            duration: 2.5,
                                            ease: "power4.out",
                                            opacity: 1,
                                        });
                                }, 300);
                            }
                        },
                        endTrigger: exitBlock.current,
                    }
                });
            }

            setStartAnimation(false);
        }
    }, []);

    return (
        <div className="offerAbout" ref={entryBlock}>
            <div className="offerAbout__union">
                <div className="offerAbout__box">
                    <div className="box">
                        <div className="box__name section-name">{data.name}</div>
                        <h1 className="box__title interText">
                            <span id="interText__information" ref={ref}></span>
                            <span className="interText__line hidden" id="console-offerAbout" ref={nameClass}></span>
                        </h1>
                        <div className="box__description text ">{data.description}</div>
                    </div>
                </div>
                <div className="offerAbout__slider" ref={exitBlock}>
                    <div className="slider">
                        <div className="slider__first_block">
                            <div className="slider__gallery">
                                <div className="gallery-box">
                                    <div className="gallery-box__items">
                                        <div className="carousel-container gallery-box__slider-first">
                                            <div className="carousel-wrapper">
                                                <div className="gallery-box__item">
                                                    {data.sliderPhotoLineFirst.map((item, id) => (
                                                        <div key={id} className="block">
                                                            <div className="block__photo gallery-box__image-wrap">
                                                                <div className="block__photo_mask">
                                                                    <img src={item.photo} alt="" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                            <div className="carousel-wrapper">
                                                <div className="gallery-box__item">
                                                    {data.sliderPhotoLineFirst.map((item, id) => (
                                                        <div key={id} className="block">
                                                            <div className="block__photo gallery-box__image-wrap">
                                                                <div className="block__photo_mask">
                                                                    <img src={item.photo} alt="" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                            <div className="carousel-wrapper">
                                                <div className="gallery-box__item">
                                                    {data.sliderPhotoLineFirst.map((item, id) => (
                                                        <div key={id} className="block">
                                                            <div className="block__photo gallery-box__image-wrap">
                                                                <div className="block__photo_mask">
                                                                    <img src={item.photo} alt="" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                            <div className="carousel-wrapper">
                                                <div className="gallery-box__item">
                                                    {data.sliderPhotoLineFirst.map((item, id) => (
                                                        <div key={id} className="block">
                                                            <div className="block__photo gallery-box__image-wrap">
                                                                <div className="block__photo_mask">
                                                                    <img src={item.photo} alt="" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                            <div className="carousel-wrapper">
                                                <div className="gallery-box__item">
                                                    {data.sliderPhotoLineFirst.map((item, id) => (
                                                        <div key={id} className="block">
                                                            <div className="block__photo gallery-box__image-wrap">
                                                                <div className="block__photo_mask">
                                                                    <img src={item.photo} alt="" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                            <div className="carousel-wrapper">
                                                <div className="gallery-box__item">
                                                    {data.sliderPhotoLineFirst.map((item, id) => (
                                                        <div key={id} className="block">
                                                            <div className="block__photo gallery-box__image-wrap">
                                                                <div className="block__photo_mask">
                                                                    <img src={item.photo} alt="" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="slider__second_block">
                            <div className="slider__gallery">
                                <div className="gallery-box-second">
                                    <div className="gallery-box-second__items">
                                        <div className="carousel-container gallery-box-second__slider-first">
                                            <div className="carousel-wrapper">
                                                <div className="gallery-box-second__item">
                                                    {data.sliderPhotoLineSecond.map((item, id) => (
                                                        <div key={id} className="block">
                                                            <div className="block__photo gallery-box-second__image-wrap">
                                                                <div className="block__photo_mask">
                                                                    <img src={item.photo} alt="" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                            <div className="carousel-wrapper">
                                                <div className="gallery-box-second__item">
                                                    {data.sliderPhotoLineSecond.map((item, id) => (
                                                        <div key={id} className="block">
                                                            <div className="block__photo gallery-box-second__image-wrap">
                                                                <div className="block__photo_mask">
                                                                    <img src={item.photo} alt="" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                            <div className="carousel-wrapper">
                                                <div className="gallery-box-second__item">
                                                    {data.sliderPhotoLineSecond.map((item, id) => (
                                                        <div key={id} className="block">
                                                            <div className="block__photo gallery-box-second__image-wrap">
                                                                <div className="block__photo_mask">
                                                                    <img src={item.photo} alt="" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                            <div className="carousel-wrapper">
                                                <div className="gallery-box-second__item">
                                                    {data.sliderPhotoLineSecond.map((item, id) => (
                                                        <div key={id} className="block">
                                                            <div className="block__photo gallery-box-second__image-wrap">
                                                                <div className="block__photo_mask">
                                                                    <img src={item.photo} alt="" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                            <div className="carousel-wrapper">
                                                <div className="gallery-box-second__item">
                                                    {data.sliderPhotoLineSecond.map((item, id) => (
                                                        <div key={id} className="block">
                                                            <div className="block__photo gallery-box-second__image-wrap">
                                                                <div className="block__photo_mask">
                                                                    <img src={item.photo} alt="" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                            <div className="carousel-wrapper">
                                                <div className="gallery-box-second__item">
                                                    {data.sliderPhotoLineSecond.map((item, id) => (
                                                        <div key={id} className="block">
                                                            <div className="block__photo gallery-box-second__image-wrap">
                                                                <div className="block__photo_mask">
                                                                    <img src={item.photo} alt="" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="slider__third_block">
                            <div className="slider__gallery">
                                <div className="gallery-box">
                                    <div className="gallery-box__items">
                                        <div className="carousel-container gallery-box__slider-first">
                                            <div className="carousel-wrapper">
                                                <div className="gallery-box__item">
                                                    {data.sliderPhotoLineThird.map((item, id) => (
                                                        <div key={id} className="block">
                                                            <div className="block__photo gallery-box__image-wrap">
                                                                <div className="block__photo_mask">
                                                                    <img src={item.photo} alt="" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                            <div className="carousel-wrapper">
                                                <div className="gallery-box__item">
                                                    {data.sliderPhotoLineThird.map((item, id) => (
                                                        <div key={id} className="block">
                                                            <div className="block__photo gallery-box__image-wrap">
                                                                <div className="block__photo_mask">
                                                                    <img src={item.photo} alt="" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                            <div className="carousel-wrapper">
                                                <div className="gallery-box__item">
                                                    {data.sliderPhotoLineThird.map((item, id) => (
                                                        <div key={id} className="block">
                                                            <div className="block__photo gallery-box__image-wrap">
                                                                <div className="block__photo_mask">
                                                                    <img src={item.photo} alt="" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                            <div className="carousel-wrapper">
                                                <div className="gallery-box__item">
                                                    {data.sliderPhotoLineThird.map((item, id) => (
                                                        <div key={id} className="block">
                                                            <div className="block__photo gallery-box__image-wrap">
                                                                <div className="block__photo_mask">
                                                                    <img src={item.photo} alt="" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                            <div className="carousel-wrapper">
                                                <div className="gallery-box__item">
                                                    {data.sliderPhotoLineThird.map((item, id) => (
                                                        <div key={id} className="block">
                                                            <div className="block__photo gallery-box__image-wrap">
                                                                <div className="block__photo_mask">
                                                                    <img src={item.photo} alt="" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                            <div className="carousel-wrapper">
                                                <div className="gallery-box__item">
                                                    {data.sliderPhotoLineThird.map((item, id) => (
                                                        <div key={id} className="block">
                                                            <div className="block__photo gallery-box__image-wrap">
                                                                <div className="block__photo_mask">
                                                                    <img src={item.photo} alt="" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="slider__fourth_block">
                            <div className="slider__gallery">
                                <div className="gallery-box">
                                    <div className="gallery-box__items">
                                        <div className="carousel-container gallery-box__slider-first">
                                            <div className="carousel-wrapper">
                                                <div className="gallery-box__item">
                                                    {data.sliderPhotoLineFourth.map((item, id) => (
                                                        <div key={id} className="block">
                                                            <div className="block__photo gallery-box__image-wrap">
                                                                <div className="block__photo_mask">
                                                                    <img src={item.photo} alt="" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                            <div className="carousel-wrapper">
                                                <div className="gallery-box__item">
                                                    {data.sliderPhotoLineFourth.map((item, id) => (
                                                        <div key={id} className="block">
                                                            <div className="block__photo gallery-box__image-wrap">
                                                                <div className="block__photo_mask">
                                                                    <img src={item.photo} alt="" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                            <div className="carousel-wrapper">
                                                <div className="gallery-box__item">
                                                    {data.sliderPhotoLineFourth.map((item, id) => (
                                                        <div key={id} className="block">
                                                            <div className="block__photo gallery-box__image-wrap">
                                                                <div className="block__photo_mask">
                                                                    <img src={item.photo} alt="" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                            <div className="carousel-wrapper">
                                                <div className="gallery-box__item">
                                                    {data.sliderPhotoLineFourth.map((item, id) => (
                                                        <div key={id} className="block">
                                                            <div className="block__photo gallery-box__image-wrap">
                                                                <div className="block__photo_mask">
                                                                    <img src={item.photo} alt="" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                            <div className="carousel-wrapper">
                                                <div className="gallery-box__item">
                                                    {data.sliderPhotoLineFourth.map((item, id) => (
                                                        <div key={id} className="block">
                                                            <div className="block__photo gallery-box__image-wrap">
                                                                <div className="block__photo_mask">
                                                                    <img src={item.photo} alt="" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                            <div className="carousel-wrapper">
                                                <div className="gallery-box__item">
                                                    {data.sliderPhotoLineFourth.map((item, id) => (
                                                        <div key={id} className="block">
                                                            <div className="block__photo gallery-box__image-wrap">
                                                                <div className="block__photo_mask">
                                                                    <img src={item.photo} alt="" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="slider__fifth_block">
                            <div className="slider__gallery">
                                <div className="gallery-box">
                                    <div className="gallery-box__items">
                                        <div className="carousel-container gallery-box__slider-first">
                                            <div className="carousel-wrapper">
                                                <div className="gallery-box__item">
                                                    {data.sliderPhotoLineFifth.map((item, id) => (
                                                        <div key={id} className="block">
                                                            <div className="block__photo gallery-box__image-wrap">
                                                                <div className="block__photo_mask">
                                                                    <img src={item.photo} alt="" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                            <div className="carousel-wrapper">
                                                <div className="gallery-box__item">
                                                    {data.sliderPhotoLineFifth.map((item, id) => (
                                                        <div key={id} className="block">
                                                            <div className="block__photo gallery-box__image-wrap">
                                                                <div className="block__photo_mask">
                                                                    <img src={item.photo} alt="" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                            <div className="carousel-wrapper">
                                                <div className="gallery-box__item">
                                                    {data.sliderPhotoLineFifth.map((item, id) => (
                                                        <div key={id} className="block">
                                                            <div className="block__photo gallery-box__image-wrap">
                                                                <div className="block__photo_mask">
                                                                    <img src={item.photo} alt="" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                            <div className="carousel-wrapper">
                                                <div className="gallery-box__item">
                                                    {data.sliderPhotoLineFifth.map((item, id) => (
                                                        <div key={id} className="block">
                                                            <div className="block__photo gallery-box__image-wrap">
                                                                <div className="block__photo_mask">
                                                                    <img src={item.photo} alt="" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                            <div className="carousel-wrapper">
                                                <div className="gallery-box__item">
                                                    {data.sliderPhotoLineFifth.map((item, id) => (
                                                        <div key={id} className="block">
                                                            <div className="block__photo gallery-box__image-wrap">
                                                                <div className="block__photo_mask">
                                                                    <img src={item.photo} alt="" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                            <div className="carousel-wrapper">
                                                <div className="gallery-box__item">
                                                    {data.sliderPhotoLineFifth.map((item, id) => (
                                                        <div key={id} className="block">
                                                            <div className="block__photo gallery-box__image-wrap">
                                                                <div className="block__photo_mask">
                                                                    <img src={item.photo} alt="" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Offer;