// React
import React, { useState } from 'react';
import { Link } from "react-router-dom";

// Gsap
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

// Components
import ButtonMainSubscribe from "../buttons/ButtonMainSubscribe/ButtonMainSubscribe";
import Socials from "../Socials/Socials";

// Style
import style from '../Footer/footer.scss';


const Footer = ({ data, content }) => {

    const [checkForms, setCheckForms] = useState(false);

    return (
        <footer className="footer">
            <div className="footer__tuch">
                <div className="tuch">
                    <div className="tuch__photo">
                        <div className="tuch__photo_mask">
                            <div className="tuch__photo_decor"></div>
                            <video width="750" height="500" autoPlay muted="autoPlay" loop="autoPlay">
                                <source src={content.tuchVideo} type="video/mp4" />
                            </video>
                        </div>
                        <img src={content.logo} className="tuch__logo" alt="tuchLogo" />
                        <h2 className="tuch__title">{content.tuchTitle}</h2>
                        <Link to={content.tuchButtonLink}>
                            <div className="tuch__button">
                                <div className="tuch__button_mask">
                                    <div className="color">
                                        <div className="tuch__button_text">
                                            <span>{content.tuchButton}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Link>
                    </div>
                </div>
            </div>
            <div className="footer__union">
                <div className="footer__block">
                    <div className="footer__line"></div>
                    <div className="footer__logo">
                        <img src={content.logo} alt="Logo" />
                    </div>
                    <div className="footer__description">{content.descriptionFooter}</div>
                    <div className="footer__forms">
                        <div className="forms">
                            <div className="forms__box">
                                <div className={`forms__button ${checkForms ? '' : 'forms__button_active'}`}
                                    onClick={() => { setCheckForms(false) }}>
                                    {content.labelName1}
                                </div>
                                <div className={`forms__button ${!checkForms ? '' : 'forms__button_active'}`}
                                    onClick={() => { setCheckForms(true) }}>
                                    {content.labelName2}
                                </div>
                                {!checkForms && <div className="forms__block">
                                    <input type="text" placeholder='Your email here' />
                                    <div className="forms__button"><ButtonMainSubscribe text={content.buttonLabel} /></div>
                                </div>}
                                {checkForms && <div className="forms__block">
                                    <input type="text" placeholder='Your email here' />
                                    <div className="forms__button">
                                        <ButtonMainSubscribe text={content.buttonLabel} /></div>
                                </div>}
                            </div>
                        </div>
                    </div>
                    <div className="footer__copyright">
                        {content.copyright}
                    </div>
                </div>
                <div className="footer__box">
                    <div className="footer__line"></div>
                    <ul className="box__items">
                        {data.menuFirst1.map((item) => (
                            <li className="box__item" key={item.id}>
                                <Link to={item.link}>
                                    {item.name}
                                </Link>
                            </li>
                        ))}
                    </ul>
                    <div className="box__socials">
                        <Socials />
                    </div>
                    <div className="box__forms">
                        <div className="forms">
                            <div className="forms__box">
                                <div className={`forms__button ${checkForms ? '' : 'forms__button_active'}`}
                                    onClick={() => { setCheckForms(false) }}>
                                    {content.labelName1}
                                </div>
                                <div className={`forms__button ${!checkForms ? '' : 'forms__button_active'}`}
                                    onClick={() => { setCheckForms(true) }}>
                                    {content.labelName2}
                                </div>
                                {!checkForms && <div className="forms__block">
                                    <input type="text" placeholder='Your email here' />
                                    <div className="forms__button"><ButtonMainSubscribe text={content.buttonLabel} /></div>
                                </div>}
                                {checkForms && <div className="forms__block">
                                    <input type="text" placeholder='Your email here' />
                                    <div className="forms__button">
                                        <ButtonMainSubscribe text={content.buttonLabel} /></div>
                                </div>}
                            </div>
                        </div>
                    </div>
                    <div className="box__information">
                        <div className="information">
                            <div className="box__copyright">{content.copyright}</div>
                            <div className="information__items">
                                {data.menuSecond2.map((item) => (
                                    <li className="information__item" key={item.id}>
                                        <Link to={item.link}>
                                            {item.name}</Link>
                                    </li>
                                ))}
                            </div>
                            <div className="information__creater"><Link to="/">
                                Designed by:
                                <span className="creater">Intex</span></Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer
