// React
import React, { useState, useRef, useEffect } from 'react';

// Gsap
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

// Style
import style from "../Accordion/accordion.scss";


const Accordion = ({ title, content }) => {

    const [isActive, setIsActive] = useState(false);
    const [isShow, setIsShow] = useState(false);
    const [isShowBottom, setIsShowBottom] = useState(true);
    const [isShowBack, setIsShowBack] = useState(false);

    const [isOpenBlock, setIsOpenBlock] = useState(false);

    const contentBlock = useRef();

    const showContent = (e) => {
        if (isActive) {
            setIsActive(false);
            setIsShowBack(false);

            setTimeout(() => {
                setIsShowBottom(true);
            }, 10);

        } else {
            // hideDescription();
            setIsActive(true);
            setIsShowBottom(false);

            setTimeout(() => {
                setIsShowBack(true);
            }, 400);

            gsap.fromTo(".accordion__content_text", { y: -30, opacity: 0, }, { y: 0, duration: 1.5, ease: "power4.out", opacity: 1, })
        }
    }

    useEffect(() => {
        if (isActive) {
            setTimeout(() => {
                setIsShow(true)
            }, 3000);
        }
        setIsShow(false)
    }, [isActive])


    return (
        <div className={isShowBack ? 'accordion__item accordion__item_active' : 'accordion__item'} onClick={(e) => showContent(e)}>
            <div className="accordion__title">
                <div className="accordion__title_back">
                    <div className="accordion__title_back-color"></div>
                </div>
                <span className={isShowBottom ? 'accordion__title_text' : 'accordion__title_text accordion__title_text_active'}>{title}</span>
                {isActive && <div className="accordion__check-minus"></div>}
                {!isActive && <div className="accordion__check-plus">
                    <span></span></div>}
            </div>
            <div className={isActive ? 'accordion__content accordion__content_all' : 'accordion__content'} ref={contentBlock}>
                <div className="accordion__content_box">
                    {isActive && <div className="accordion__content_text">
                        {
                            content
                        }
                    </div>}
                </div>
                <div className="accordion__bottom-decor">
                    <div className="accordion__bottom-decor-color"></div>
                </div>
            </div>
        </div>
    );
};

export default Accordion