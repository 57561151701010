// React
import React from 'react';
import { Link } from "react-router-dom";

// Style
import style from './buttonMainMobile.scss';


function ButtonMainMobile({ text, link, }) {
    return (
        <Link className="buttonMainMobile" to={{ pathname: link }}><span>{text}</span></Link>
    )
}

export default ButtonMainMobile;