// React
import React, { useRef, useEffect, useState } from 'react';

// Gsap
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

// Animate for title
import interText from '../../../../functions/animatedText';
import blinkingLine from '../../../../functions/blinkingLine';

// Components
import ProgressBlock from "./ProgressBlock";

// Functions
import { progressSlider } from '../../../../functions/progressSlider';

// Slider
import SlickSlider from './Slider';

// Store
import carts from "../../../../store/progressCarts";

// Style
import style from "../Progress/progress.scss";


export default React.memo(function Progress({ data }) {
    var carouselRef = useRef(null);
    var figureRef = useRef(null);
    var navRef = useRef(null);
    var lineActive = useRef(null);
    const nameClass = useRef(null);
    const entryBlock = useRef(null);
    const exitBlock = useRef(null);
    const ref = useRef(null);

    const [startAnimation, setStartAnimation] = useState(true);
    const [detectSafari, setDetectSafari] = useState(true);
    const [detectOtherDevice, setDetectOtherDevice] = useState(true);
    const [lineWidthCount, setLineWidthCount] = useState(1);

    useEffect(() => {
        if (startAnimation) {
            progressSlider(carouselRef, figureRef, navRef, lineActive, nameClass);

            var tl = gsap.timeline({
                scrollTrigger: {
                    trigger: entryBlock.current,
                    start: "top 400px",
                    once: true,
                    onEnter: run => {
                        interText([data.title], ref);
                        blinkingLine(nameClass.current);

                        setTimeout(() => {
                            gsap.fromTo(".progress__carousel-box", { y: 20, opacity: 0, }, { y: 0, duration: 1.5, ease: "power4.out", opacity: 1, })
                        }, 1000);

                        setTimeout(() => {
                            gsap.fromTo(".progress__bar", { y: 20, opacity: 0, }, { y: 0, duration: 1.5, ease: "power4.out", opacity: 1, })
                        }, 1200);
                    },
                    endTrigger: exitBlock.current,
                }
            });
            setStartAnimation(false);
        }

        const is_safari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
        var fBlock = document.querySelector([".progress__carousel-box"]);
        var sBlock = document.querySelector([".progress__slider"]);
        sBlock.style.display = 'none';

        if (is_safari) {
            sBlock.style.display = 'block';
            fBlock.style.display = 'none';

            if (startAnimation) {
                var tl = gsap.timeline({
                    scrollTrigger: {
                        trigger: entryBlock.current,
                        start: "top 400px",
                        once: true,
                        onEnter: run => {
                            interText([data.title], ref);
                            blinkingLine(nameClass.current);

                            setTimeout(() => {
                                gsap.fromTo(".progress__slider", { y: 20, opacity: 0, }, { y: 0, duration: 1.5, ease: "power4.out", opacity: 1, })
                            }, 1000);
                        },
                        endTrigger: exitBlock.current,
                    }
                });
                setStartAnimation(false);
            }
        }
    }, []);

    useEffect(() => {
        const arrowPrevie = document.querySelector([".progress .progress__control_prev"]),
            arrowNext = document.querySelector([".progress .progress__control_next"]),
            lineProgress = document.querySelector([".progress .progress__bar .count .count__box .count__line_active"]),
            nextArrow = document.querySelector([".progress__control_next"]),
            nextArrowSlider = document.querySelector([".progress .slick-next"]),
            previewArrow = document.querySelector([".progress__control_prev"]),
            previewArrowDefault = document.querySelector([".progress__control_prev-default"]),
            previewArrowSlider = document.querySelector([".progress .slick-arrow"]);

        let lineWidthCount = 0;
        lineProgress.style.width = 12.5 * 1 + '%';
        arrowPrevie.style.display = 'none';
        previewArrowDefault.style.display = 'block';

        arrowPrevie.addEventListener('click', () => {
            arrowPrevie.style.opacity = 0;
            arrowPrevie.style.display = 'none';
            arrowPrevie.style.transition = '0.55s opacity, 0.55s display';

            setTimeout(() => {
                arrowPrevie.style.opacity = 1;
                arrowPrevie.style.display = 'block';

                if (lineWidthCount === 0) {
                    arrowPrevie.style.display = 'none';
                    previewArrowDefault.style.display = 'block';
                }
            }, 1000);

            if (lineWidthCount > 0) {
                lineWidthCount--;
            }
            if (lineWidthCount === 0) {
                lineProgress.style.width = 12.5 * 1 + '%';
                arrowPrevie.style.display = 'none';
            } else if (lineWidthCount === 1) {
                lineProgress.style.width = 12.5 * 2 + '%';
                arrowPrevie.style.display = 'none';
            } else if (lineWidthCount === 2) {
                lineProgress.style.width = 12.5 * 3 + '%';
            }
            else if (lineWidthCount === 3) {
                lineProgress.style.width = 12.5 * 4 + '%';
            } else if (lineWidthCount === 4) {
                lineProgress.style.width = 12.5 * 5 + '%';
            } else if (lineWidthCount === 5) {
                lineProgress.style.width = 12.5 * 6 + '%';
            } else if (lineWidthCount === 6) {
                lineProgress.style.width = 12.5 * 7 + '%';
            } else if (lineWidthCount === 7) {
                lineProgress.style.width = 12.5 * 8 + '%';
            } else if (lineWidthCount === 8) {
                lineProgress.style.width = 12.5 * 1 + '%';
            } else {
                arrowPrevie.style.opacity = 0;
                arrowPrevie.style.display = 'none';
                arrowPrevie.style.transition = '0.55s opacity, 0.55s display';

                setTimeout(() => {
                    arrowPrevie.style.opacity = 1;
                    arrowPrevie.style.display = 'block';
                }, 1000);
            }
        });

        arrowNext.addEventListener('click', () => {
            lineWidthCount++;
            arrowNext.style.opacity = 0;
            arrowNext.style.display = 'none';
            arrowNext.style.transition = '0.55s opacity, 0.55s display';
            previewArrowDefault.style.display = 'none';

            setTimeout(() => {
                arrowNext.style.opacity = 1;
                arrowNext.style.display = 'block';
            }, 1000);

            if (lineWidthCount === 0) {
                lineProgress.style.width = 12.5 * 1 + '%';
            } else if (lineWidthCount === 1) {
                lineProgress.style.width = 12.5 * 2 + '%';
                arrowPrevie.style.display = 'block';
            } else if (lineWidthCount === 2) {
                lineProgress.style.width = 12.5 * 3 + '%';
            }
            else if (lineWidthCount === 3) {
                lineProgress.style.width = 12.5 * 4 + '%';
            } else if (lineWidthCount === 4) {
                lineProgress.style.width = 12.5 * 5 + '%';
            } else if (lineWidthCount === 5) {
                lineProgress.style.width = 12.5 * 6 + '%';
            } else if (lineWidthCount === 6) {
                lineProgress.style.width = 12.5 * 7 + '%';
            } else if (lineWidthCount === 7) {
                lineProgress.style.width = 12.5 * 8 + '%';
            } else if (lineWidthCount === 8) {
                lineProgress.style.width = 12.5 * 1 + '%';
                lineWidthCount = 0;
                arrowPrevie.style.display = 'none';
            }
        });

        previewArrow.addEventListener('click', () => {
            previewArrowSlider.click();
        });

        nextArrow.addEventListener('click', () => {
            nextArrowSlider.click();
        });
    }, [lineWidthCount]);


    return (
        <>
            <div className="progress" ref={entryBlock}>
                <div className="progress__name">
                    <div className="progress__name_mark"></div>
                    {data.name}
                </div>
                <h2 className="progress__title interText ">
                    <span id="interText__information" ref={ref}></span>
                    <span className="interText__line hidden" id="console-progress" ref={nameClass}></span>
                </h2>
                {detectOtherDevice &&
                    <div className="progress__carousel-box">
                        <div ref={carouselRef} className="carousel">
                            <figure ref={figureRef}>
                                {carts.map((cart, id) => (
                                    <ProgressBlock key={id} data={carts[id].data} id={carts[id].id} items={carts[id].items} check={carts[id].check} />
                                ))}
                            </figure>
                            <nav className="control" ref={navRef}>
                                <button className="control__prev"></button>
                                <button className="control__next"></button>
                            </nav>
                        </div>
                    </div>
                }
                {
                    detectSafari && <div className="progress__slider">
                        <div className="slider">
                            <SlickSlider carts={carts} />
                            <nav className="control">
                                <button className="progress__control_prev-default"></button>
                                <button className="progress__control_prev"></button>
                                <button className="progress__control_next"></button>
                            </nav>
                        </div>
                    </div>
                }
                <div className="progress__bar" ref={exitBlock} >
                    <div className="check"></div>
                    <div className="count">
                        <div className="count__box">
                            <div ref={lineActive} className="count__line_active">
                            </div>
                        </div>
                        <div className="count__name">Progress</div>
                    </div>
                </div>
            </div>
        </>
    )
});