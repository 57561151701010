// React
import React, { useRef, useEffect, useState } from 'react';
import { Link } from "react-router-dom";

// Gsap
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

// Animate for title
import interText from '../../../../functions/animatedText';
import blinkingLine from '../../../../functions/blinkingLine';

// Components
import ButtonMainBlog from "../../../../components/buttons/ButtonMainBlog/ButtonMainBlog";

// Store
import articles from "../../../../store/articles";

// Style
import style from '../BlogSection/blogSection.scss';

let startQuantity = 1;
let finishQuantity = 4;


const BlogSection = ({ data }) => {

    const entryBlock = useRef(null);
    const exitBlock = useRef(null);
    const ref = useRef(null);
    const nameClass = useRef(null);

    const [startAnimation, setStartAnimation] = useState(true);

    useEffect(() => {
        if (startAnimation) {
            var tl = gsap.timeline({
                scrollTrigger: {
                    trigger: entryBlock.current,
                    start: "top 600px",
                    once: true,
                    endTrigger: exitBlock.current,
                    onEnter: run => {
                        interText([data.title], ref);
                        blinkingLine(nameClass.current);

                        setTimeout(() => {
                            gsap.fromTo('.BlogSection__mainpost', { y: 30, opacity: 0, }, { y: 0, duration: 1.5, ease: "power4.out", opacity: 1, });
                        }, 100);

                        setTimeout(() => {
                            gsap.fromTo(".BlogSection__block .block__name-section", { y: 30, opacity: 0, }, { y: 0, duration: 1.5, ease: "power4.out", opacity: 1, });
                        }, 200);

                        setTimeout(() => {
                            gsap.fromTo(".benefits__description", { y: 30, opacity: 0, }, { y: 0, duration: 1.5, ease: "power4.out", opacity: 1, });
                        }, 300);

                        setTimeout(() => {
                            gsap.fromTo(".BlogSection__button", { y: 30, opacity: 0, }, { y: 0, duration: 1.5, ease: "power4.out", opacity: 1, });
                        }, 3500);

                        function blogItem() {
                            const items = document.querySelectorAll([".BlogSection__union .block__union"]);

                            let nextNumber = 0;
                            let countNumber = items.length;

                            let startAnimateItem = setInterval(() => {
                                gsap.fromTo(items[nextNumber], { y: 30, opacity: 0, }, { y: 0, duration: 1.5, ease: "power4.out", opacity: 1, });

                                nextNumber++;

                                if (nextNumber === countNumber) {
                                    clearTimeout(startAnimateItem);
                                }
                            }, 1000);
                        }
                        blogItem();
                    },
                }
            });
            setStartAnimation(false);
        }
    }, []);

    return (
        <div className="blogSection" ref={entryBlock}>
            <h2 className="BlogSection__title interText">
                <span id="interText__information" ref={ref}></span>
                <span className="interText__line hidden" id="console-blogSection" ref={nameClass}></span>
            </h2>
            <div className="BlogSection__union">
                <div className="BlogSection__mainpost">
                    <div className="mainpost__photo">
                        <img src={articles[0].photo} alt="" className="mainpost__img" />
                    </div>
                    <Link to={`/blog/article${articles[0].link}`}>
                        <h3 className="mainpost__title">{articles[0].title}</h3>
                    </Link>
                    <div className="mainpost__description">{articles[0].description}</div>
                    <Link to={`/blog/article${articles[0].link}`} className="mainpost__link read-more">
                        {articles[0].linkName}
                    </Link>
                </div>
                <div className="BlogSection__block">
                    <div className="block__name-section">{data.sudTitle}</div>
                    {articles.slice(startQuantity, finishQuantity).map((article) => (
                        <Link to={`/blog/article${article.link}`} className="block__union" key={article.id}>
                            <div className="block__box_photo">
                                <img src={article.photo} alt={article.title} className='block__photo' />
                            </div>
                            <div to="/" className="block__box">
                                <h3 className="block__title">{article.title}</h3>
                                <div className="block__description">{article.description}</div>
                            </div>
                        </Link>
                    ))}
                </div>
            </div>
            <div className="BlogSection__button" ref={exitBlock}>
                <ButtonMainBlog text={data.linkName} link={data.link} />
            </div>
        </div>
    )
}

export default BlogSection;