// React
import React from 'react';
import { Link } from "react-router-dom";

// Style
import style from '../Article/article.scss';


const Article = ({ data }) => {

    return (
        <>
            <Link to={`article/${data.id}`} className="article">
                <div className="article__photo">
                    <img src={data.photo} alt={data.title} />
                </div>
                <h2 className="article__title">{data.title}</h2>
                <div className="article__description">{data.description}</div>
                <div className="article__button read-more">
                    {data.linkName}
                    <svg width="25" height="26" viewBox="0 0 25 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g opacity="0.4" filter="url(#filter0_d_0_2650)">
                            <path fillRule="evenodd" clipRule="evenodd" d="M8.00026 8.60254L10.3013 13.0001L8.00026 17.3977H9.6875L10.838 15.1989H11.4772L12.4999 13.0001L11.4772 10.8013H10.838L9.6875 8.60254H8.00026Z" fill="url(#paint0_linear_0_2650)" />
                        </g>
                        <g filter="url(#filter1_d_0_2650)">
                            <path fillRule="evenodd" clipRule="evenodd" d="M12.5004 8.60254L14.8014 13.0001L12.5004 17.3977H14.1876L15.3382 15.1989H15.9773L17 13.0001L15.9773 10.8013H15.3382L14.1876 8.60254H12.5004Z" fill="url(#paint1_linear_0_2650)" />
                        </g>
                        <defs>
                            <filter id="filter0_d_0_2650" x="0.000244141" y="0.602539" width="20.4996" height="24.7951" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                <feOffset />
                                <feGaussianBlur stdDeviation="4" />
                                <feColorMatrix type="matrix" values="0 0 0 0 0.533333 0 0 0 0 0.458824 0 0 0 0 0.984314 0 0 0 0.5 0" />
                                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_0_2650" />
                                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_0_2650" result="shape" />
                            </filter>
                            <filter id="filter1_d_0_2650" x="4.50037" y="0.602539" width="20.4996" height="24.7951" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                <feOffset />
                                <feGaussianBlur stdDeviation="4" />
                                <feColorMatrix type="matrix" values="0 0 0 0 0.533333 0 0 0 0 0.458824 0 0 0 0 0.984314 0 0 0 0.5 0" />
                                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_0_2650" />
                                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_0_2650" result="shape" />
                            </filter>
                            <linearGradient id="paint0_linear_0_2650" x1="3.70496" y1="13.0001" x2="13.0498" y2="13.0001" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#FC64FC" />
                            </linearGradient>
                            <linearGradient id="paint1_linear_0_2650" x1="8.20508" y1="13.0001" x2="17.5499" y2="13.0001" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#FC64FC" />
                            </linearGradient>
                        </defs>
                    </svg>
                </div>
            </Link>
        </>
    )
}

export default Article;