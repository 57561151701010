export function changingProgressBlock(progressAllBlocks, nextButton, previous) {
    const progressBlock1 = progressAllBlocks[0];
    const progressBlock2 = progressAllBlocks[1];
    const progressBlock3 = progressAllBlocks[2];
    const progressBlock4 = progressAllBlocks[3];
    const progressBlock5 = progressAllBlocks[4];
    const progressBlock6 = progressAllBlocks[5];
    const progressBlock7 = progressAllBlocks[6];
    const progressBlock8 = progressAllBlocks[7];

    progressBlock1.style.opacity = '1';
    progressBlock2.style.opacity = '0.1';
    progressBlock3.style.opacity = '0.1';
    progressBlock4.style.opacity = '0.1';
    progressBlock5.style.opacity = '0.1';
    progressBlock6.style.opacity = '0.1';
    progressBlock7.style.opacity = '0.1';
    progressBlock8.style.opacity = '0.1';

    let countClick = 1;
    nextButton.addEventListener('click', () => {
        countClick++;

        if (countClick === 1) {
            progressBlock1.classList.remove('addOpacity');
            progressBlock2.classList.add('addOpacity');
            progressBlock3.classList.add('addOpacity');
            progressBlock4.classList.add('addOpacity');
            progressBlock5.classList.add('addOpacity');
            progressBlock6.classList.add('addOpacity');
            progressBlock7.classList.add('addOpacity');
            progressBlock8.classList.add('addOpacity');
        }
        if (countClick === 2) {
            progressBlock1.classList.add('addOpacity');
            progressBlock2.classList.remove('addOpacity');
            progressBlock3.classList.add('addOpacity');
            progressBlock4.classList.add('addOpacity');
            progressBlock5.classList.add('addOpacity');
            progressBlock6.classList.add('addOpacity');
            progressBlock7.classList.add('addOpacity');
            progressBlock8.classList.add('addOpacity');
        }
        if (countClick === 3) {
            progressBlock1.classList.add('addOpacity');
            progressBlock2.classList.add('addOpacity');
            progressBlock3.classList.remove('addOpacity');
            progressBlock4.classList.add('addOpacity');
            progressBlock5.classList.add('addOpacity');
            progressBlock6.classList.add('addOpacity');
            progressBlock7.classList.add('addOpacity');
            progressBlock8.classList.add('addOpacity');
        }
        if (countClick === 4) {
            progressBlock1.classList.add('addOpacity');
            progressBlock2.classList.add('addOpacity');
            progressBlock3.classList.add('addOpacity');
            progressBlock4.classList.remove('addOpacity');
            progressBlock5.classList.add('addOpacity');
            progressBlock6.classList.add('addOpacity');
            progressBlock7.classList.add('addOpacity');
            progressBlock8.classList.add('addOpacity');
        }
        if (countClick === 5) {
            progressBlock1.classList.add('addOpacity');
            progressBlock2.classList.add('addOpacity');
            progressBlock3.classList.add('addOpacity');
            progressBlock4.classList.add('addOpacity');
            progressBlock5.classList.remove('addOpacity');
            progressBlock6.classList.add('addOpacity');
            progressBlock7.classList.add('addOpacity');
            progressBlock8.classList.add('addOpacity');
        }
        if (countClick === 6) {
            progressBlock1.classList.add('addOpacity');
            progressBlock2.classList.add('addOpacity');
            progressBlock3.classList.add('addOpacity');
            progressBlock4.classList.add('addOpacity');
            progressBlock5.classList.add('addOpacity');
            progressBlock6.classList.remove('addOpacity');
            progressBlock7.classList.add('addOpacity');
            progressBlock8.classList.add('addOpacity');
        }
        if (countClick === 7) {
            progressBlock1.classList.add('addOpacity');
            progressBlock2.classList.add('addOpacity');
            progressBlock3.classList.add('addOpacity');
            progressBlock4.classList.add('addOpacity');
            progressBlock5.classList.add('addOpacity');
            progressBlock6.classList.add('addOpacity');
            progressBlock7.classList.remove('addOpacity');
            progressBlock8.classList.add('addOpacity');
        }
        if (countClick === 8) {
            progressBlock1.classList.add('addOpacity');
            progressBlock2.classList.add('addOpacity');
            progressBlock3.classList.add('addOpacity');
            progressBlock4.classList.add('addOpacity');
            progressBlock5.classList.add('addOpacity');
            progressBlock6.classList.add('addOpacity');
            progressBlock7.classList.add('addOpacity');
            progressBlock8.classList.remove('addOpacity');
            countClick = 0;
        }
    })

    previous.addEventListener('click', () => {
        if (countClick > 1) {
            countClick--;
        }
        if (countClick === 0) {
            progressBlock1.classList.add('addOpacity');
            progressBlock2.classList.add('addOpacity');
            progressBlock3.classList.add('addOpacity');
            progressBlock4.classList.add('addOpacity');
            progressBlock5.classList.add('addOpacity');
            progressBlock6.classList.add('addOpacity');
            progressBlock7.classList.remove('addOpacity');
            progressBlock8.classList.add('addOpacity');
            countClick = 7;
        }
        if (countClick === 1) {
            progressBlock1.classList.remove('addOpacity');
            progressBlock2.classList.add('addOpacity');
            progressBlock3.classList.add('addOpacity');
            progressBlock4.classList.add('addOpacity');
            progressBlock5.classList.add('addOpacity');
            progressBlock6.classList.add('addOpacity');
            progressBlock7.classList.add('addOpacity');
            progressBlock8.classList.add('addOpacity');
        }
        if (countClick === 2) {
            progressBlock1.classList.add('addOpacity');
            progressBlock2.classList.remove('addOpacity');
            progressBlock3.classList.add('addOpacity');
            progressBlock4.classList.add('addOpacity');
            progressBlock5.classList.add('addOpacity');
            progressBlock6.classList.add('addOpacity');
            progressBlock7.classList.add('addOpacity');
            progressBlock8.classList.add('addOpacity');
        }
        if (countClick === 3) {
            progressBlock1.classList.add('addOpacity');
            progressBlock2.classList.add('addOpacity');
            progressBlock3.classList.remove('addOpacity');
            progressBlock4.classList.add('addOpacity');
            progressBlock5.classList.add('addOpacity');
            progressBlock6.classList.add('addOpacity');
            progressBlock7.classList.add('addOpacity');
            progressBlock8.classList.add('addOpacity');
        }
        if (countClick === 4) {
            progressBlock1.classList.add('addOpacity');
            progressBlock2.classList.add('addOpacity');
            progressBlock3.classList.add('addOpacity');
            progressBlock4.classList.remove('addOpacity');
            progressBlock5.classList.add('addOpacity');
            progressBlock6.classList.add('addOpacity');
            progressBlock7.classList.add('addOpacity');
            progressBlock8.classList.add('addOpacity');
        }
        if (countClick === 5) {
            progressBlock1.classList.add('addOpacity');
            progressBlock2.classList.add('addOpacity');
            progressBlock3.classList.add('addOpacity');
            progressBlock4.classList.add('addOpacity');
            progressBlock5.classList.remove('addOpacity');
            progressBlock6.classList.add('addOpacity');
            progressBlock7.classList.add('addOpacity');
            progressBlock8.classList.add('addOpacity');
        }
        if (countClick === 6) {
            progressBlock1.classList.add('addOpacity');
            progressBlock2.classList.add('addOpacity');
            progressBlock3.classList.add('addOpacity');
            progressBlock4.classList.add('addOpacity');
            progressBlock5.classList.add('addOpacity');
            progressBlock6.classList.remove('addOpacity');
            progressBlock7.classList.add('addOpacity');
            progressBlock8.classList.add('addOpacity');
            console.log('111');
        }
        if (countClick === 7) {
            progressBlock1.classList.add('addOpacity');
            progressBlock2.classList.add('addOpacity');
            progressBlock3.classList.add('addOpacity');
            progressBlock4.classList.add('addOpacity');
            progressBlock5.classList.add('addOpacity');
            progressBlock6.classList.add('addOpacity');
            progressBlock7.classList.remove('addOpacity');
            progressBlock8.classList.add('addOpacity');
        }
        if (countClick === 8) {
            progressBlock1.classList.add('addOpacity');
            progressBlock2.classList.add('addOpacity');
            progressBlock3.classList.add('addOpacity');
            progressBlock4.classList.add('addOpacity');
            progressBlock5.classList.add('addOpacity');
            progressBlock6.classList.add('addOpacity');
            progressBlock7.classList.add('addOpacity');
            progressBlock8.classList.remove('addOpacity');
        }
    });
}
