// React
import React from 'react';
import { Link } from "react-router-dom";

// Style
import style from './buttonOffer.scss';


function ButtonOffer({ text, decor, link, isPopup }) {
    return (
        <Link to={{ pathname: link }} className={decor ? "buttonOffer buttonOfferLine" : "buttonOffer"}>
            <div className="buttonOffer__mask">
                <div className="color">
                    <span className="buttonOffer__text">{text}</span> {decor && <span className="buttonOffer__line"></span>}
                </div>
            </div>
        </Link >
    )
}

export default ButtonOffer;