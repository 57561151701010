// React
import React from 'react';

// Style
import style from './buttonMainSubscribe.scss';


function ButtonMainSubscribe({ text }) {
    return (
        <button className="buttonMainSubscribe subscribe-button">
            <div className="buttonMainSubscribe__block">
                <div className="buttonMainSubscribe__block_line-1 buttonMainSubscribe__block_line-2"></div>
            </div>
            <span className="buttonMainSubscribe__text">{text}</span>
        </button>
    )
}

export default ButtonMainSubscribe;