const articles = [
    {
        id: 1,
        data: '12 jul 2022',
        photo: 'https://preview.vb.in.ua/rentalz/img/blog-photo-1.jpg',
        title: 'Article name 1',
        description: 'Unlock a life of luxury experiences and a higher social status by becoming part of our fast-growing community...',
        linkName: 'Read',
        link: '/1',
        content: [
            {
                title: 'Title will be here',
                p: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus sit amet luctus venenatis, lectus magna fringilla urna, porttitor rhoncus dolor purus non enim praesent elementum facilisis leo, vel fringilla est ullamcorper eget nulla facilisi etiam dignissim diam quis enim lobortis scelerisque fermentum dui faucibus in ornare quam viverra orci sagittis eu volutpat odio facilisis mauris sit amet massa vitae tortor condimentum lacinia quis vel eros donec ac odio tempor orci dapibus ultrices in iaculis nunc sed augue lacus, viverra vitae congue eu, consequat ac felis donec et odio pellentesque diam volutpat commodo sed egestas egestas fringilla phasellus faucibusm ipsum dolor sit amet.',
                item: 'Consectetur adipiscing elit ut aliquam, purus sit amet luctus venenatis, lectus magna fringilla urna.',
                photo: 'https://preview.vb.in.ua/rentalz/img/blog-photo-2.jpg',
            },
        ]
    },
    {
        id: 2,
        data: '12 jul 2022',
        photo: 'https://preview.vb.in.ua/rentalz/img/blog-photo-2.jpg',
        title: 'Article name 2',
        description: 'Unlock a life of luxury experiences and a higher social status by becoming part of our fast-growing community...',
        linkName: 'Read',
        link: '/2',
        content: [
            {
                title: 'Title will be here',
                p: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus sit amet luctus venenatis, lectus magna fringilla urna, porttitor rhoncus dolor purus non enim praesent elementum facilisis leo, vel fringilla est ullamcorper eget nulla facilisi etiam dignissim diam quis enim lobortis scelerisque fermentum dui faucibus in ornare quam viverra orci sagittis eu volutpat odio facilisis mauris sit amet massa vitae tortor condimentum lacinia quis vel eros donec ac odio tempor orci dapibus ultrices in iaculis nunc sed augue lacus, viverra vitae congue eu, consequat ac felis donec et odio pellentesque diam volutpat commodo sed egestas egestas fringilla phasellus faucibusm ipsum dolor sit amet.',
                item: 'Consectetur adipiscing elit ut aliquam, purus sit amet luctus venenatis, lectus magna fringilla urna.',
                photo: 'https://preview.vb.in.ua/rentalz/img/blog-photo-2.jpg',
            },
        ]
    },
    {
        id: 3,
        data: '12 jul 2022',
        photo: 'https://preview.vb.in.ua/rentalz/img/blog-photo-2.jpg',
        title: 'Article name 3',
        description: 'Unlock a life of luxury experiences and a higher social status by becoming part of our fast-growing community...',
        linkName: 'Read',
        link: '/3',
        content: [
            {
                title: 'Title will be here333',
                p: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus sit amet luctus venenatis, lectus magna fringilla urna, porttitor rhoncus dolor purus non enim praesent elementum facilisis leo, vel fringilla est ullamcorper eget nulla facilisi etiam dignissim diam quis enim lobortis scelerisque fermentum dui faucibus in ornare quam viverra orci sagittis eu volutpat odio facilisis mauris sit amet massa vitae tortor condimentum lacinia quis vel eros donec ac odio tempor orci dapibus ultrices in iaculis nunc sed augue lacus, viverra vitae congue eu, consequat ac felis donec et odio pellentesque diam volutpat commodo sed egestas egestas fringilla phasellus faucibusm ipsum dolor sit amet.',
                item: 'Consectetur adipiscing elit ut aliquam, purus sit amet luctus venenatis, lectus magna fringilla urna.',
                photo: 'https://preview.vb.in.ua/rentalz/img/blog-photo-2.svg',
                photo: 'https://preview.vb.in.ua/rentalz/img/blog-photo-2.jpg',
            },
        ]
    },
    {
        id: 4,
        data: '12 jul 2022',
        photo: 'https://preview.vb.in.ua/rentalz/img/blog-photo-2.jpg',
        title: 'Article name 4',
        description: 'Unlock a life of luxury experiences and a higher social status by becoming part of our fast-growing community...',
        linkName: 'Read',
        link: '/4',
        content: [
            {
                title: 'Title will be here',
                p: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus sit amet luctus venenatis, lectus magna fringilla urna, porttitor rhoncus dolor purus non enim praesent elementum facilisis leo, vel fringilla est ullamcorper eget nulla facilisi etiam dignissim diam quis enim lobortis scelerisque fermentum dui faucibus in ornare quam viverra orci sagittis eu volutpat odio facilisis mauris sit amet massa vitae tortor condimentum lacinia quis vel eros donec ac odio tempor orci dapibus ultrices in iaculis nunc sed augue lacus, viverra vitae congue eu, consequat ac felis donec et odio pellentesque diam volutpat commodo sed egestas egestas fringilla phasellus faucibusm ipsum dolor sit amet.',
                item: 'Consectetur adipiscing elit ut aliquam, purus sit amet luctus venenatis, lectus magna fringilla urna.',
                photo: 'https://preview.vb.in.ua/rentalz/img/blog-photo-2.jpg',
            },
        ]
    },
    {
        id: 5,
        data: '12 jul 2022',
        photo: 'https://preview.vb.in.ua/rentalz/img/blog-photo-2.jpg',
        title: 'Article name 5',
        description: 'Unlock a life of luxury experiences and a higher social status by becoming part of our fast-growing community...',
        linkName: 'Read',
        link: '/5',
        content: [
            {
                title: 'Title will be here',
                p: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus sit amet luctus venenatis, lectus magna fringilla urna, porttitor rhoncus dolor purus non enim praesent elementum facilisis leo, vel fringilla est ullamcorper eget nulla facilisi etiam dignissim diam quis enim lobortis scelerisque fermentum dui faucibus in ornare quam viverra orci sagittis eu volutpat odio facilisis mauris sit amet massa vitae tortor condimentum lacinia quis vel eros donec ac odio tempor orci dapibus ultrices in iaculis nunc sed augue lacus, viverra vitae congue eu, consequat ac felis donec et odio pellentesque diam volutpat commodo sed egestas egestas fringilla phasellus faucibusm ipsum dolor sit amet.',
                item: 'Consectetur adipiscing elit ut aliquam, purus sit amet luctus venenatis, lectus magna fringilla urna.',
                photo: 'https://preview.vb.in.ua/rentalz/img/blog-photo-2.jpg',
            },
        ]
    },
    {
        id: 6,
        data: '12 jul 2022',
        photo: 'https://preview.vb.in.ua/rentalz/img/blog-photo-2.jpg',
        title: 'Article name 6',
        description: 'Unlock a life of luxury experiences and a higher social status by becoming part of our fast-growing community...',
        linkName: 'Read',
        link: '/6',
        content: [
            {
                title: 'Title will be here',
                p: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus sit amet luctus venenatis, lectus magna fringilla urna, porttitor rhoncus dolor purus non enim praesent elementum facilisis leo, vel fringilla est ullamcorper eget nulla facilisi etiam dignissim diam quis enim lobortis scelerisque fermentum dui faucibus in ornare quam viverra orci sagittis eu volutpat odio facilisis mauris sit amet massa vitae tortor condimentum lacinia quis vel eros donec ac odio tempor orci dapibus ultrices in iaculis nunc sed augue lacus, viverra vitae congue eu, consequat ac felis donec et odio pellentesque diam volutpat commodo sed egestas egestas fringilla phasellus faucibusm ipsum dolor sit amet.',
                item: 'Consectetur adipiscing elit ut aliquam, purus sit amet luctus venenatis, lectus magna fringilla urna.',
                photo: 'https://preview.vb.in.ua/rentalz/img/blog-photo-2.jpg',
            },
        ]
    },
    {
        id: 7,
        data: '12 jul 2022',
        photo: 'https://preview.vb.in.ua/rentalz/img/blog-photo-2.jpg',
        title: 'Article name 7',
        description: 'Unlock a life of luxury experiences and a higher social status by becoming part of our fast-growing community...',
        linkName: 'Read',
        link: '/7',
        content: [
            {
                title: 'Title will be here',
                p: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus sit amet luctus venenatis, lectus magna fringilla urna, porttitor rhoncus dolor purus non enim praesent elementum facilisis leo, vel fringilla est ullamcorper eget nulla facilisi etiam dignissim diam quis enim lobortis scelerisque fermentum dui faucibus in ornare quam viverra orci sagittis eu volutpat odio facilisis mauris sit amet massa vitae tortor condimentum lacinia quis vel eros donec ac odio tempor orci dapibus ultrices in iaculis nunc sed augue lacus, viverra vitae congue eu, consequat ac felis donec et odio pellentesque diam volutpat commodo sed egestas egestas fringilla phasellus faucibusm ipsum dolor sit amet.',
                item: 'Consectetur adipiscing elit ut aliquam, purus sit amet luctus venenatis, lectus magna fringilla urna.',
                photo: 'https://preview.vb.in.ua/rentalz/img/blog-photo-2.jpg',
            },
        ]
    },
    {
        id: 8,
        data: '12 jul 2022',
        photo: 'https://preview.vb.in.ua/rentalz/img/blog-photo-2.jpg',
        title: 'Article name 8',
        description: 'Unlock a life of luxury experiences and a higher social status by becoming part of our fast-growing community...',
        linkName: 'Read',
        link: '/8',
        content: [
            {
                title: 'Title will be here',
                p: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus sit amet luctus venenatis, lectus magna fringilla urna, porttitor rhoncus dolor purus non enim praesent elementum facilisis leo, vel fringilla est ullamcorper eget nulla facilisi etiam dignissim diam quis enim lobortis scelerisque fermentum dui faucibus in ornare quam viverra orci sagittis eu volutpat odio facilisis mauris sit amet massa vitae tortor condimentum lacinia quis vel eros donec ac odio tempor orci dapibus ultrices in iaculis nunc sed augue lacus, viverra vitae congue eu, consequat ac felis donec et odio pellentesque diam volutpat commodo sed egestas egestas fringilla phasellus faucibusm ipsum dolor sit amet.',
                item: 'Consectetur adipiscing elit ut aliquam, purus sit amet luctus venenatis, lectus magna fringilla urna.',
                photo: 'https://preview.vb.in.ua/rentalz/img/blog-photo-2.jpg',
            },
        ]
    },
    {
        id: 9,
        data: '12 jul 2022',
        photo: 'https://preview.vb.in.ua/rentalz/img/blog-photo-2.jpg',
        title: 'Article name 9',
        description: 'Unlock a life of luxury experiences and a higher social status by becoming part of our fast-growing community...',
        linkName: 'Read',
        link: '/9',
        content: [
            {
                title: 'Title will be here',
                p: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus sit amet luctus venenatis, lectus magna fringilla urna, porttitor rhoncus dolor purus non enim praesent elementum facilisis leo, vel fringilla est ullamcorper eget nulla facilisi etiam dignissim diam quis enim lobortis scelerisque fermentum dui faucibus in ornare quam viverra orci sagittis eu volutpat odio facilisis mauris sit amet massa vitae tortor condimentum lacinia quis vel eros donec ac odio tempor orci dapibus ultrices in iaculis nunc sed augue lacus, viverra vitae congue eu, consequat ac felis donec et odio pellentesque diam volutpat commodo sed egestas egestas fringilla phasellus faucibusm ipsum dolor sit amet.',
                item: 'Consectetur adipiscing elit ut aliquam, purus sit amet luctus venenatis, lectus magna fringilla urna.',
                photo: 'https://preview.vb.in.ua/rentalz/img/blog-photo-2.jpg',
            },
        ]
    },
];

export default articles;