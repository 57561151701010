// React
import React from 'react';
import { Outlet } from "react-router-dom";

// Components
import Header from "../Header/Header";
import Footer from "../Footer/Footer";

// Store
import { menuHeader, menuFooter } from "../../store/menu";
import { repeatInformation } from "../../store/repeatInformation";

// Style
import style from '../Layout/layout.scss';


const Layout = () => {
    return (
        <>
            <Header data={menuHeader} content={repeatInformation} />
            <Outlet />
            <Footer data={menuFooter} content={repeatInformation} />
        </>
    )
}

export { Layout };