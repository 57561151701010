const offerAbout = {
    name: 'Our story',
    title: 'Who we are_',
    description: 'The only NFT platform where you can tap into a world of high-end luxury cars and real estate by joining a global community of crypto enthusiasts.',
    sliderPhotoLineFirst: [
        {
            photo: 'https://preview.vb.in.ua/rentalz/img/ab1.png',
        },
        {
            photo: 'https://preview.vb.in.ua/rentalz/img/about-2.jpg',
        },
        {
            photo: 'https://preview.vb.in.ua/rentalz/img/ab3.png',
        },
        {
            photo: 'https://preview.vb.in.ua/rentalz/img/ab4.png',
        },
    ],
    sliderPhotoLineSecond: [
        {
            photo: 'https://preview.vb.in.ua/rentalz/img/ab1.png',
        },
        {
            photo: 'https://preview.vb.in.ua/rentalz/img/about-2.jpg',
        },
        {
            photo: 'https://preview.vb.in.ua/rentalz/img/ab3.png',
        },
        {
            photo: 'https://preview.vb.in.ua/rentalz/img/ab4.png',
        },
    ],
    sliderPhotoLineThird: [
        {
            photo: 'https://preview.vb.in.ua/rentalz/img/ab1.png',
        },
        {
            photo: 'https://preview.vb.in.ua/rentalz/img/about-2.jpg',
        },
        {
            photo: 'https://preview.vb.in.ua/rentalz/img/ab3.png',
        },
        {
            photo: 'https://preview.vb.in.ua/rentalz/img/ab4.png',
        },
    ],
    sliderPhotoLineFourth: [
        {
            photo: 'https://preview.vb.in.ua/rentalz/img/ab1.png',
        },
        {
            photo: 'https://preview.vb.in.ua/rentalz/img/about-2.jpg',
        },
        {
            photo: 'https://preview.vb.in.ua/rentalz/img/ab3.png',
        },
        {
            photo: 'https://preview.vb.in.ua/rentalz/img/ab4.png',
        },
        {
            photo: 'https://preview.vb.in.ua/rentalz/img/ab1.png',
        },
        {
            photo: 'https://preview.vb.in.ua/rentalz/img/about-2.jpg',
        },
        {
            photo: 'https://preview.vb.in.ua/rentalz/img/ab3.png',
        },
        {
            photo: 'https://preview.vb.in.ua/rentalz/img/ab4.png',
        },
    ],
    sliderPhotoLineFifth: [
        {
            photo: 'https://preview.vb.in.ua/rentalz/img/ab1.png',
        },
        {
            photo: 'https://preview.vb.in.ua/rentalz/img/about-2.jpg',
        },
        {
            photo: 'https://preview.vb.in.ua/rentalz/img/about-2.jpg',
        },
        {
            photo: 'https://preview.vb.in.ua/rentalz/img/about-2.jpg',
        },
        {
            photo: 'https://preview.vb.in.ua/rentalz/img/ab1.png',
        },
        {
            photo: 'https://preview.vb.in.ua/rentalz/img/about-2.jpg',
        },
        {
            photo: 'https://preview.vb.in.ua/rentalz/img/about-2.jpg',
        },
        {
            photo: 'https://preview.vb.in.ua/rentalz/img/about-2.jpg',
        },
    ]
}

const aboutSectionAbout = {
    name: ' About us',
    title: 'TITLE WILL BE HERE',
    description: 'The only NFT platform where you can tap into a world of high-end luxury cars and real estate by joining a global community of crypto enthusiasts.',
    photo_1: 'https://preview.vb.in.ua/rentalz/img/about-1.webp',
    photo_2: 'https://preview.vb.in.ua/rentalz/img/about-2.webp',
    photo_3: 'https://preview.vb.in.ua/rentalz/img/about-3.webp',
    button: 'Text',
    link: '/5'
}

const videoAbout = {
    title: 'Video Section_',
    urlVideo: 'www.videohere.com',
}

const benefitsAbout = {
    title: 'Benefits_',
    ico: 'https://preview.vb.in.ua/rentalz/img/benefits-photo.jpg',
    icoSecond: 'https://preview.vb.in.ua/rentalz/img/benefits-car.png',
    video: 'https://rentalz.intex.agency/video1.mp4',
    icoMobile: 'https://preview.vb.in.ua/rentalz/img/benefits-car-mobile.png',
    items: [
        {
            id: 1,
            ico: 'https://preview.vb.in.ua/rentalz/img/benefits-luxury.svg',
            title: 'Gamifying Luxury',
            description: 'Unlock a life of luxury experiences and a higher social status by becoming part of our fast-growing community of 10,000+ members.',
        },
        {
            id: 2,
            ico: 'https://preview.vb.in.ua/rentalz/img/benefits-reach.svg',
            title: 'Global Reach',
            description: 'Unlock a life of luxury experiences and a higher social status by becoming part of our fast-growing community of 10,000+ members.',
        },
        {
            id: 3,
            ico: 'https://preview.vb.in.ua/rentalz/img/benefits-access.svg',
            title: 'Exclusive Access',
            description: 'Unlock a life of luxury experiences and a higher social status by becoming part of our fast-growing community of 10,000+ members.',
        }
    ]
};

const blogAbout = {
    title: 'Our blog_',
    description: '',
    linkName: 'Show all',
    link: '/blog',
    sudTitle: 'New articles',

}

export { offerAbout, aboutSectionAbout, videoAbout, benefitsAbout, blogAbout }; 