// React
import React, { useEffect, useRef, useState } from 'react';

// Gsap
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

// Counter
import counter from '../../../../functions/counter';

// Style
import style from "../Statistics/statistics.scss";


export default React.memo(function Counter({ data }) {

    const [run, setRun] = useState();

    const itemFirst = useRef();
    const itemSecond = useRef();
    const itemThird = useRef();
    const entryBlock = useRef(null);
    const exitBlock = useRef(null);


    useEffect(() => {
        if (document.documentElement.clientWidth < 420) {
            var tl = gsap.timeline({
                scrollTrigger: {
                    trigger: entryBlock.current,
                    start: "top 500px",
                    once: true,

                    onEnter: run => {
                        counter(Number(data.countNumberFirst), itemFirst, 1, 1000);
                        counter(Number(data.countNumberSecond), itemSecond, 1, 1000);
                        counter(Number(data.countNumberThird), itemThird, 1, 1000);
                        setRun(run + 1)

                        gsap.fromTo(".counters__block", { y: 20, opacity: 0, }, { y: 0, duration: 1.5, ease: "power4.out", opacity: 1, })
                    },
                    endTrigger: exitBlock.current,
                }
            });
        } else {
            var tl = gsap.timeline({
                scrollTrigger: {
                    trigger: entryBlock.current,
                    start: "top 700px",
                    once: true,

                    onEnter: run => {
                        counter(Number(data.countNumberFirst), itemFirst, 1, 1000);
                        counter(Number(data.countNumberSecond), itemSecond, 1, 1000);
                        counter(Number(data.countNumberThird), itemThird, 1, 1000);
                        setRun(run + 1)

                        gsap.fromTo(".counters__block", { y: 20, opacity: 0, }, { y: 0, duration: 1.5, ease: "power4.out", opacity: 1, })
                    },
                    endTrigger: exitBlock.current,
                }
            });
        }
    }, []);


    return (
        <div className="counters" ref={entryBlock}>
            <div className="counters__block">
                <div className="counters__number">
                    <span ref={itemFirst}></span>
                    {run && 'k'}
                </div>
                <div className="counters__text">
                    {data.countTitleFirst}
                </div>
            </div>
            <div className="counters__block">
                <div className="counters__number" ref={itemSecond}>
                </div>
                <div className="counters__text"> 
                    {data.countTitleSecond}
                </div>
            </div>
            <div className="counters__block">
                <div className="counters__number">
                    <span ref={itemThird}></span>
                    {run && 'k'}
                </div>
                <div className="counters__text" ref={exitBlock}>
                    {data.countTitleThird}
                </div>
            </div>
        </div>
    )
});  