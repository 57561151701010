// React
import React, { useEffect } from 'react';

// Components
import Offer from "./components/Offer/Offer";
import AboutSection from "./components/About/AboutSection";
import Video from "./components/Video/Video";
import Benefits from "./components/Benefits/Benefits";
import BlogSection from "./components/BlogSection/BlogSection";

// Store
import { offerAbout, aboutSectionAbout, videoAbout, benefitsAbout, blogAbout } from "../../store/about";

// Style
import style from '../About/about.scss';


const About = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <>
            <Offer data={offerAbout} />
            <AboutSection data={aboutSectionAbout} />
            <Video data={videoAbout} />
            <Benefits data={benefitsAbout} />
            <BlogSection data={blogAbout} />
        </>
    )
}

export default About;