// React
import React, { useEffect } from 'react';

// Components
import Page from "../../components/Templates/Page/Page";

// Store
import testPage from "../../store/testPage";


const TestPage = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <>
            <Page data={testPage} />
        </>
    )
}

export default TestPage;