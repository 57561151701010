// React
import React, { useRef, useEffect, useState } from 'react';

// Gsap
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

// Components
import Counters from "../Statistics/_Counters";
import ButtonOffer from "../../../../components/buttons/ButtonOffer/ButtonOffer";

// Animate title
import interText from '../../../../functions/animatedText';
import blinkingLine from '../../../../functions/blinkingLine';

// Style
import style from "../Statistics/statistics.scss";


export default React.memo(function Statistics({ data }) {

    const entryBlock = useRef(null);
    const exitBlock = useRef(null);
    const ref = useRef(null);
    const imgLeft = useRef(null);
    const nameClass = useRef(null);

    const [startAnimation, setStartAnimation] = useState(true);

    useEffect(() => {
        if (startAnimation) {
            if (document.documentElement.clientWidth < 420) {
                var tl = gsap.timeline({
                    scrollTrigger: {
                        trigger: entryBlock.current,
                        start: "top 300px",
                        once: true,
                        endTrigger: exitBlock.current,
                        onEnter: run => {
                            interText([data.title], ref);
                            blinkingLine(nameClass.current);

                            setTimeout(() => {
                                gsap.fromTo(".statistics__name", { y: 20, opacity: 0, }, { y: 0, duration: 1.5, ease: "power4.out", opacity: 1, })
                            }, 100);

                            if (document.documentElement.clientWidth < 420) {
                                setTimeout(() => {
                                    gsap.fromTo(".slider__photos", { opacity: 0, y: -50, }, { y: 0, duration: 2.5, ease: "power4.out", opacity: 1, })
                                }, 300);
                            } else {
                                setTimeout(() => {
                                    gsap.fromTo(".slider__photos", { opacity: 0, y: -50, }, { y: 0, duration: 2.5, ease: "power4.out", opacity: 1, })
                                }, 100);
                            }

                            setTimeout(() => {
                                gsap.fromTo(".statistics__description", { y: 30, opacity: 0, }, { y: 0, duration: 2.5, ease: "power4.out", opacity: 1, })
                            }, 300);

                            setTimeout(() => {
                                gsap.fromTo(".statistics__button_box", { y: 30, opacity: 0, }, { y: 0, duration: 2.5, ease: "power4.out", opacity: 1, })
                            }, 400);
                        },
                        toggleClass: {
                            targets: ".slider__photo_right",
                            className: "slider__photo_right_active"
                        },
                    }
                });

                setTimeout(() => {
                    var tl = gsap.timeline({
                        scrollTrigger: {
                            trigger: entryBlock.current,
                            start: "top 250px",
                            once: true,
                            toggleClass: {
                                targets: ".slider__photo_left",
                                className: "slider__photo_left_active"
                            },
                            endTrigger: exitBlock.current
                        }
                    });
                }, 300);
            } else {
                var tl = gsap.timeline({
                    scrollTrigger: {
                        trigger: entryBlock.current,
                        start: "top 200px",
                        once: true,
                        endTrigger: exitBlock.current,
                        onEnter: run => {
                            interText([data.title], ref);
                            blinkingLine(nameClass.current);

                            setTimeout(() => {
                                gsap.fromTo(".statistics__name", { y: 20, opacity: 0, }, { y: 0, duration: 1.5, ease: "power4.out", opacity: 1, })
                            }, 100);

                            if (document.documentElement.clientWidth < 420) {
                                setTimeout(() => {
                                    gsap.fromTo(".slider__photos", { opacity: 0, y: -50, }, { y: 0, duration: 2.5, ease: "power4.out", opacity: 1, })
                                }, 1000);
                            } else {
                                setTimeout(() => {
                                    gsap.fromTo(".slider__photos", { opacity: 0, y: -50, }, { y: 0, duration: 2.5, ease: "power4.out", opacity: 1, })
                                }, 100);
                            }

                            setTimeout(() => {
                                gsap.fromTo(".statistics__description", { y: 30, opacity: 0, }, { y: 0, duration: 2.5, ease: "power4.out", opacity: 1, })
                            }, 700);

                            setTimeout(() => {
                                gsap.fromTo(".statistics__button_box", { y: 30, opacity: 0, }, { y: 0, duration: 2.5, ease: "power4.out", opacity: 1, })
                            }, 900);
                        },
                        toggleClass: {
                            targets: ".slider__photo_right",
                            className: "slider__photo_right_active"
                        },
                    }
                });

                setTimeout(() => {
                    var tl = gsap.timeline({
                        scrollTrigger: {
                            trigger: entryBlock.current,
                            start: "top 250px",
                            once: true,
                            toggleClass: {
                                targets: ".slider__photo_left",
                                className: "slider__photo_left_active"
                            },
                            endTrigger: exitBlock.current
                        }
                    });
                }, 300);
            }
            setStartAnimation(false);
        }
    }, []);


    return (
        <div className="statistics" ref={entryBlock}>
            <div className="statistics__union">
                <div className="statistics__block">
                    <div className="statistics__name_box">
                        <div className="statistics__name section-name">{data.nameSection}</div>
                    </div>
                    <h2 className="statistics__title interText ">
                        <span id="interText__information" ref={ref}></span>
                        <span className="interText__line hidden" id="console-statistics" ref={nameClass}></span>
                    </h2>
                    <div className="statistics__description_box">
                        <div className="statistics__description text">{data.description}</div>
                    </div>
                    <div className="statistics__button_box">
                        <div className="statistics__button">
                            <ButtonOffer text={data.button} link={data.link} decor={true} isPopup={data.link ? true : false} />
                        </div>
                    </div>
                </div>
                <div className="statistics__slider">
                    <div className="slider__photos">
                        <div className="slider__photo slider__photo_left" ref={imgLeft}>
                            <img src={data.photo_1} alt="sliderPhoto" />
                        </div>
                        <div className="slider__photo slider__photo_main">
                            <div className="slider__photo_main_mask">
                                <img src={data.photo_2} alt="sliderPhoto" />
                            </div>
                        </div>
                        <div className="slider__photo_right_box">
                            <div className="slider__photo slider__photo_right">
                                <img src={data.photo_3} alt="sliderPhoto" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="statistics__counters" ref={exitBlock}>
                <Counters data={data} />
            </div>
        </div>
    )
}); 