// React
import React, { useRef, useEffect } from 'react';

// Gsap
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

// Animate for title
import interText from '../../functions/animatedText';
import blinkingLine from '../../functions/blinkingLine';

// Components
import FaqItem from "../../components/Accordion/Accordion";

// Store
import { dataFaq } from "../../store/faq";

// Style
import style from '../Faq/faq.scss';

gsap.registerPlugin(ScrollTrigger);


const Faq = () => {
    const entryBlock = useRef(null);
    const exitBlock = useRef(null);
    const ref = useRef(null);
    const nameClass = useRef(null);

    useEffect(() => {
        window.scrollTo(0, 0);
        var tl = gsap.timeline({
            scrollTrigger: {
                trigger: entryBlock.current,
                start: "top 400px",
                once: true,
                onEnter: run => {
                    interText([dataFaq.title], ref);
                    blinkingLine(nameClass.current);

                    gsap.fromTo(".faq__description",
                        {
                            y: 30,
                            opacity: 0,
                        },
                        {
                            y: 0,
                            duration: 1.5,
                            ease: "power4.out",
                            opacity: 1,
                        })

                    function faqItem() {
                        const items = document.querySelectorAll([".faq-page .accordion__item"]);

                        let nextNumber = 0;
                        let countNumber = items.length;

                        let startAnimateItem = setInterval(() => {
                            gsap.fromTo(items[nextNumber], { y: 30, opacity: 0, }, { y: 0, duration: 1.5, ease: "power4.out", opacity: 1, });

                            nextNumber++;

                            if (nextNumber === countNumber) {
                                clearTimeout(startAnimateItem);
                            }
                        }, 300);
                    }
                    faqItem();
                },
                endTrigger: exitBlock.current,
            }
        });
    }, []);


    return (
        <div className="faq faq-page" ref={entryBlock}>
            <div className="faq__block">
                <h1 className="faq__title interText " ref={exitBlock}>
                    <span id="interText__information" ref={ref}></span>
                    <span className="interText__line hidden" id="console-faq-page" ref={nameClass}></span>
                </h1>
                <div className="faq__description text ">{dataFaq.description}</div>
                <div className="faq__items">
                    <div className="faq__accordion accordion">
                        {dataFaq.items.map(({ title, content, index }) => (
                            <FaqItem title={title} content={content} key={index} />
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Faq;