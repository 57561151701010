// React
import React, { useEffect } from 'react';

// Components
import Form from "./components/Form/Form";
import Network from "./components/Network/Network";

// Store
import { networkContacts, formContacts } from "../../store/contacts";

// Style
import style from '../Contacts/contacts.scss';


const Contacts = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <>
            <div className="contacts">
                <div className="contacts__union">
                    <div className="contacts__network ">
                        <Network data={networkContacts} />
                    </div>
                    <div className="contacts__form">
                        <Form data={formContacts} />
                    </div>
                </div>
            </div>
        </>
    )
}

export default Contacts;