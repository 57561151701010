// React
import React from 'react';

// Browser router
import { Routes, Route } from "react-router-dom";

// Component
import { BasicContainer } from "../BasicContainer/BasicContainer";
import { MarketplaceContainer } from "../MarketplaceContainer/MarketplaceContainer";

// Style
import './app.scss';


function App() {
  return (
    <>
      <Routes>
        <Route path={'/*'} element={<BasicContainer />} />
        <Route path={'/market'} element={<MarketplaceContainer />} />
      </Routes>
    </>
  );
}

export default App;
