// React
import React from 'react';
import { Link } from "react-router-dom";

//img
import { ReactComponent as Instagram } from '../../assets/img/instagram.svg';
import { ReactComponent as Discord } from '../../assets/img/discord.svg';
import { ReactComponent as Facebook } from '../../assets/img/facebook.svg';
import { ReactComponent as Twitter } from '../../assets/img/twitter.svg';

// Store
import socials from "../../store/socials";

// Style
import style from '../Socials/socials.scss';


const Socials = () => {

    return (
        <div className="socials">
            <Link to={socials.instagram} className="socials__item">
                <Instagram width="20" height="20" />
            </Link>
            <Link to={socials.discord} className="socials__item">
                <Discord width="20" height="17" />
            </Link>
            <Link to={socials.facebook} className="socials__item">
                <Facebook width="11" height="18" />
            </Link>
            <Link to={socials.twitter} className="socials__item">
                <Twitter width="20" height="18" />
            </Link>
        </div>
    )
}

export default Socials;