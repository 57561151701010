// React
import React from 'react';
import { Link } from "react-router-dom";

// Components
import Socials from "../../../../components/Socials/Socials";

// Style
import style from '../Network/network.scss';


const Network = ({ data }) => {

    return (
        <>
            <div className="network">
                <div className="network__name section-name">{data.name1}</div>
                <Link to={data.mail} className="network__mail">
                    {data.mail}
                </Link>
                <div className="network__name section-name">{data.name2}</div>
                <Socials />
            </div>
        </>
    )
}

export default Network;