// React
import React, { useRef, useEffect, useState } from 'react';

// Gsap
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

// Animate for title
import interText from '../../../../functions/animatedText';
import blinkingLine from '../../../../functions/blinkingLine';

// Style
import style from "../Video/video.scss";


function Video({ data }) {

    const [play, setPlay] = useState(false);

    const playVideo = () => {
        setPlay(true);
    }

    const entryBlock = useRef(null);
    const exitBlock = useRef(null);
    const ref = useRef(null);
    const nameClass = useRef(null);

    const [startAnimation, setStartAnimation] = useState(true);


    useEffect(() => {
        if (startAnimation) {
            var tl = gsap.timeline({
                scrollTrigger: {
                    trigger: entryBlock.current,
                    start: "top 400px",
                    once: true,
                    onEnter: run => {
                        interText([data.title], ref);
                        blinkingLine(nameClass.current);

                        setTimeout(() => {
                            gsap.fromTo(".video__block", { y: 80, opacity: 0, }, { y: 0, duration: 2.5, ease: "power4.out", opacity: 1, })
                        }, 300);
                    },
                    endTrigger: exitBlock.current
                }
            });
        }
    }, []);

    return (
        <div className="video-section" ref={entryBlock}>
            <h2 className="video__title interText ">
                <span id="interText__information" ref={ref}></span>
                <span className="interText__line hidden" id="console-videoSection" ref={nameClass}></span>
            </h2>
            <div className="video__block" ref={exitBlock}>
                <div className="video">
                    {!play && <div className="video__preloader">
                        <div className="video__play" onClick={playVideo}></div>
                    </div>}
                    {play && <div className="video__box">
                        {data.urlVideo}
                    </div>}
                </div>
            </div>
        </div>
    )
}

export default Video