// React
import React, { useEffect, useRef } from 'react';
import { Link, useParams } from "react-router-dom";

// Gsap
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

// Components
import Social from "../../Socials/Socials";
import ButtonMainMobile from "../../buttons/ButtonMainMobile/ButtonMainMobile"

// Store
import articles from "../../../store/articles";
import blog from "../../../store/blog";

// Style
import style from './articlePage.scss';

gsap.registerPlugin(ScrollTrigger);


const ArticlePage = () => {

    const { id } = useParams();
    const articleId = id - 1;
    const data = articles[articleId];
    const content = data.content[0];

    const progressLine = useRef();

    useEffect(() => {
        window.scrollTo(0, 0);
        let count = 100;
        gsap.to('progress', {
            value: count,
            ease: 'none',
            scrollTrigger: { scrub: 0.3 }
        });
    }, []);


    return (
        <>
            <div className="articlePage">
                <div className="article">
                    <div className="article__block">
                        <div className="article__block_box_photo">
                            <img src={content.photo} className="article__block_background" alt="" />
                        </div>
                        <div className="article__block_box">
                            <h1 className="article__title">
                                {data.title}
                            </h1>
                            <div className="article__data">
                                {data.data}
                                <span>
                                    6 minute read
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="article__box">
                        <Link to={`/blog`} className="article__link">
                            <svg width="21" height="25" viewBox="0 0 21 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g filter="url(#filter0_d_1_1766)">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M12.0405 16.4489L9.97425 12.5L12.0405 8.55118L10.5254 8.55118L9.49231 10.5256L8.91835 10.5256L8.00001 12.5L8.91835 14.4744L9.49231 14.4744L10.5254 16.4489L12.0405 16.4489Z" fill="url(#paint0_linear_1_1766)" />
                                </g>
                                <defs>
                                    <filter id="filter0_d_1_1766" x="0" y="0.551147" width="20.0405" height="23.8977" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                        <feFlood floodOpacity="0" result="BackgroundImageFix" />
                                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                        <feOffset />
                                        <feGaussianBlur stdDeviation="4" />
                                        <feColorMatrix type="matrix" values="0 0 0 0 0.533333 0 0 0 0 0.458824 0 0 0 0 0.984314 0 0 0 0.5 0" />
                                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1_1766" />
                                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1_1766" result="shape" />
                                    </filter>
                                    <linearGradient id="paint0_linear_1_1766" x1="15.8975" y1="12.5" x2="7.50623" y2="12.5" gradientUnits="userSpaceOnUse">
                                        <stop stopColor="#8875FB" />
                                    </linearGradient>
                                </defs>
                            </svg>
                            {blog.allPostButton}</Link>
                        <p>
                            {content.p}
                        </p>
                        <h2>
                            {content.title}
                        </h2>
                        <p>
                            {content.p}
                        </p>
                        <ul>
                            <li>
                                {content.item}
                            </li>
                            <li>
                                {content.item}
                            </li>
                            <li>
                                {content.item}
                            </li>
                        </ul>
                        <h2>
                            {content.title}
                        </h2>
                        <p>
                            {content.p}
                        </p>
                        <ol>
                            <li>
                                {content.item}
                            </li>
                            <li>
                                {content.item}
                            </li>
                            <li>
                                {content.item}
                            </li>
                        </ol>
                        <p>
                            {content.p}
                        </p>
                        <img src={content.photo} alt="" />
                        <p>
                            {content.p}
                        </p>
                        <p>
                            {content.p}
                        </p>
                        <blockquote>
                            <svg width="36" height="25" viewBox="0 0 36 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g filter="url(#filter0_d_1_1795)">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M8 8L12.6512 16.8889H19.1111L17.0439 12.4444H15.7519L13.4264 8H8ZM22.3152 8H16.8889L21.5401 16.8889H28L25.9328 12.4444H24.6408L22.3152 8Z" fill="url(#paint0_linear_1_1795)" />
                                </g>
                                <defs>
                                    <filter id="filter0_d_1_1795" x="0" y="0" width="36" height="24.8889" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                        <feFlood floodOpacity="0" result="BackgroundImageFix" />
                                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                        <feOffset />
                                        <feGaussianBlur stdDeviation="4" />
                                        <feColorMatrix type="matrix" values="0 0 0 0 0.988235 0 0 0 0 0.392157 0 0 0 0 0.988235 0 0 0 1 0" />
                                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1_1795" />
                                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1_1795" result="shape" />
                                    </filter>
                                    <linearGradient id="paint0_linear_1_1795" x1="14.1111" y1="11.8889" x2="36.3333" y2="18.5556" gradientUnits="userSpaceOnUse">
                                        <stop stopColor="#FC64FC" />
                                        <stop offset="1" stopColor="#FE9040" />
                                    </linearGradient>
                                </defs>
                            </svg>
                            {content.p}
                        </blockquote>
                        <h2>{content.title}</h2>
                        <p>
                            {content.p}
                        </p>
                        <div className="article__social">
                            <div className="social">
                                <div className="social__name section-name">
                                    {blog.buttonSocial}
                                </div>
                                <Social />
                            </div>
                        </div>
                    </div>
                    <div className="article__similars">
                        <div className="similars">
                            <div className="similars__block">
                                <h2 className="similars__title styleText">
                                    {blog.titleMoreArticle}
                                </h2>
                                <Link to="/blog" className='similars__link'>
                                    <span>{blog.buttonMoreArticle}</span>
                                </Link>
                            </div>
                            <div className="similars__items">
                                {
                                    articles.slice(0, 4).map((article) => (
                                        <Link to={`/blog/article/${article.id}`} className="similars__item" key={article.id}>
                                            <div className="similars__photo_box">
                                                <img src={article.photo} alt={article.title} className="similars__photo" />
                                            </div>
                                            <h3 className="similars__title">
                                                {article.title}
                                            </h3>
                                            <div className="similars__description">
                                                {article.description}
                                            </div>
                                            <div className="similars__button read-more">{article.linkName}
                                                <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <g opacity="0.4" filter="url(#filter0_d_1_1818)">
                                                        <path fillRule="evenodd" clipRule="evenodd" d="M4.00026 5.10254L6.30132 9.50011L4.00026 13.8977H5.6875L6.83803 11.6989H7.47721L8.4999 9.50011L7.47721 7.30132H6.83803L5.6875 5.10254H4.00026Z" fill="url(#paint0_linear_1_1818)" />
                                                    </g>
                                                    <g filter="url(#filter1_d_1_1818)">
                                                        <path fillRule="evenodd" clipRule="evenodd" d="M8.50037 5.10254L10.8014 9.50011L8.50037 13.8977H10.1876L11.3381 11.6989H11.9773L13 9.50011L11.9773 7.30132H11.3381L10.1876 5.10254H8.50037Z" fill="url(#paint1_linear_1_1818)" />
                                                    </g>
                                                    <defs>
                                                        <filter id="filter0_d_1_1818" x="0.000259399" y="1.10254" width="12.4996" height="16.7949" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                                            <feFlood floodOpacity="0" result="BackgroundImageFix" />
                                                            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                                            <feOffset />
                                                            <feGaussianBlur stdDeviation="2" />
                                                            <feColorMatrix type="matrix" values="0 0 0 0 0.988235 0 0 0 0 0.392157 0 0 0 0 0.988235 0 0 0 1 0" />
                                                            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1_1818" />
                                                            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1_1818" result="shape" />
                                                        </filter>
                                                        <filter id="filter1_d_1_1818" x="3.50037" y="0.102539" width="14.4996" height="18.7949" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                                            <feFlood floodOpacity="0" result="BackgroundImageFix" />
                                                            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                                            <feOffset />
                                                            <feGaussianBlur stdDeviation="2.5" />
                                                            <feColorMatrix type="matrix" values="0 0 0 0 0.988235 0 0 0 0 0.392157 0 0 0 0 0.988235 0 0 0 1 0" />
                                                            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1_1818" />
                                                            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1_1818" result="shape" />
                                                        </filter>
                                                        <linearGradient id="paint0_linear_1_1818" x1="-0.295037" y1="9.50011" x2="9.0498" y2="9.50011" gradientUnits="userSpaceOnUse">
                                                            <stop stopColor="#FC64FC" />
                                                        </linearGradient>
                                                        <linearGradient id="paint1_linear_1_1818" x1="4.20507" y1="9.50011" x2="13.5499" y2="9.50011" gradientUnits="userSpaceOnUse">
                                                            <stop stopColor="#FC64FC" />
                                                        </linearGradient>
                                                    </defs>
                                                </svg>
                                            </div>
                                        </Link>
                                    ))
                                }
                            </div>
                            <div className="similars__all_button">
                                <ButtonMainMobile text={blog.buttonMoreArticle} link={blog.linkAll} />
                            </div>
                        </div>
                    </div>
                    <div className="article__progress" ref={progressLine}>
                        <div className="progress">
                            <div className="progress__container">
                                <div className="progress__line">
                                    <progress max="100" value="0"></progress>
                                </div>
                                {/* <div className="progress__number">
                                    23%
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ArticlePage;