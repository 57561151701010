// React
import React from 'react';
import { Link } from "react-router-dom";

// Style
import style from './buttonMainBlog.scss';


function ButtonMainBlog({ text, link, }) {
    return (
        <Link className="buttonMainBlog" to={{ pathname: link }}>
            <div className="buttonMainBlog__mask">
                <div className="color">
                    <div className="buttonMainBlog__text">
                        <span>{text}</span>
                    </div>
                </div>
            </div>
        </Link>
    )
}

export default ButtonMainBlog;