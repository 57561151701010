// React
import React, { useRef, useEffect, useState } from 'react';

// Gsap
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

// Animate for title
import interText from '../../../../functions/animatedText';
import blinkingLine from '../../../../functions/blinkingLine';

// Componets
import CarouselBlock from "./CarouselBlock/CarouselBlock";
import HowItWorksSlider from './HowItWorksSlider';

// Store
import carts from "../../../../store/progressCarts";

// Components
import ProgressBlock from "../Progress/ProgressBlock";

// Functions
import { howItworksSlider } from '../../../../functions/howItworksSlider';

// Style
import style from "../HowItWorks/howitworks.scss";


// Slider
const responsive = {
    tablet: {
        breakpoint: { max: 768, min: 421 },
        items: 2
    },
    mobile: {
        breakpoint: { max: 420, min: 0 },
        items: 1
    }
};


export default React.memo(function HowItWorks({ data }) {
    const firstItem = useRef();
    const secondItem = useRef();
    const thirdItem = useRef();
    const fourthItem = useRef();
    const entryBlock = useRef(null);
    const exitBlock = useRef(null);
    const ref = useRef(null);
    const refSecond = useRef(null);
    const nameClass = useRef(null);
    const nameClassSecond = useRef(null);

    const [startAnimation, setStartAnimation] = useState(true);

    useEffect(() => {
        if (startAnimation) {
            var tl = gsap.timeline({
                scrollTrigger: {
                    trigger: entryBlock.current,
                    start: "top 400px",
                    once: true,
                    onEnter: run => {
                        interText([data.title], ref);
                        blinkingLine(nameClass.current);
                        interText([data.title], refSecond);
                        blinkingLine(nameClassSecond.current);

                        setTimeout(() => {
                            gsap.fromTo(".process__item_1", { y: 30, opacity: 0, }, { y: 0, duration: 2.5, ease: "power4.out", opacity: 1, })
                            gsap.fromTo(".process__union_mob .slick-slider", { y: 30, opacity: 0, }, { y: 0, duration: 2.5, ease: "power4.out", opacity: 1, })
                        }, 400);

                        setTimeout(() => {
                            gsap.fromTo(".process__item_2", { y: 30, opacity: 0, }, { y: 0, duration: 2.5, ease: "power4.out", opacity: 1, })
                        }, 500);

                        setTimeout(() => {
                            gsap.fromTo(".process__item_3", { y: 30, opacity: 0, }, { y: 0, duration: 2.5, ease: "power4.out", opacity: 1, })
                        }, 600);

                        setTimeout(() => {
                            gsap.fromTo(".process__item_4", { y: 30, opacity: 0, }, { y: 0, duration: 2.5, ease: "power4.out", opacity: 1, })
                        }, 700);

                        setTimeout(() => {
                            gsap.fromTo(".cart_container", { y: 30, opacity: 0, }, { y: 0, duration: 2.5, ease: "power4.out", opacity: 1, })
                        }, 200);

                        howItworksSlider(firstItem, secondItem, thirdItem, fourthItem);
                    },
                    endTrigger: exitBlock.current,
                }
            });
            setStartAnimation(false);
        }
    }, []);

    return (
        <div className="howitworks" ref={entryBlock}>
            <div className="howitworks__process">
                <div className="process__container">
                    <div className="process__union process__union_block">
                        <div className="process__slider">
                            <div className="slider__box">
                                <div className="process__box">
                                    <div className="cart_container">
                                        <div className="cart">
                                            <div className="howitworks__box">
                                                <div className="howitworks__box_mask">
                                                    <img src={data.items[0].img} alt="item" />
                                                </div>

                                                <span className="howitworks__number">
                                                    04
                                                </span>
                                            </div>
                                        </div>
                                        <div className="cart">
                                            <div className="howitworks__box">
                                                <div className="howitworks__box_mask">
                                                    <img src={data.items[1].img} alt="item" />
                                                </div>
                                                <span className="howitworks__number">
                                                    03
                                                </span>
                                            </div>
                                        </div>
                                        <div className="cart">
                                            <div className="howitworks__box">
                                                <div className="howitworks__box_mask">
                                                    <img src={data.items[2].img} alt="item" />
                                                </div>
                                                <span className="howitworks__number">
                                                    02
                                                </span>
                                            </div>
                                        </div>
                                        <div className="cart">
                                            <div className="howitworks__box">
                                                <div className="howitworks__box_mask">
                                                    <img src={data.items[3].img} alt="item" />
                                                </div>
                                                <span className="howitworks__number">
                                                    01
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="process__block">
                            <h2 className="process__title interText">
                                <span id="interText__information" ref={ref}></span>
                                <span className="interText__line hidden" id="console-howitworks" ref={nameClass}></span>
                            </h2>
                            <ul className="process__items">
                                <li className="process__item process__item_active process__item_1" ref={firstItem}>
                                    <div className="process__name">
                                        <div className="process__ico_default process__ico_active"></div>
                                        <span className="process__default process__active">{data.items[0].step}</span>{data.items[0].stepName}
                                    </div>
                                    <div className="process__description text">
                                        {data.items[0].description}
                                    </div>
                                </li>
                                <li className="process__item process__item_2" ref={secondItem}>
                                    <div className="process__name">
                                        <div className="process__ico_default process__ico_active"></div>
                                        <span className="process__default process__active">{data.items[1].step}</span>{data.items[1].stepName}
                                    </div>
                                    <div className="process__description text">
                                        {data.items[1].description}
                                    </div>
                                </li>
                                <li className="process__item process__item_3" ref={thirdItem}>
                                    <div className="process__name">
                                        <div className="process__ico_default process__ico_active"></div>
                                        <span className="process__default process__active">{data.items[2].step}</span>{data.items[2].stepName}
                                    </div>
                                    <div className="process__description text">
                                        {data.items[2].description}
                                    </div>
                                </li>
                                <li className="process__item process__item_4" ref={fourthItem}>
                                    <div className="process__name">
                                        <div className="process__ico_default process__ico_active"></div>
                                        <span className="process__default process__active">{data.items[3].step}</span>{data.items[3].stepName}
                                    </div>
                                    <div className="process__description text">
                                        {data.items[3].description}
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="process__union_mob">
                    <h2 className="process__title interText">
                        <span id="interText__information" ref={refSecond}></span>
                        <span className="interText__line hidden" id="console-howitworks" ref={nameClassSecond}></span>
                    </h2>
                    <HowItWorksSlider data={data.items} />
                </div>
            </div>
            <div className="howitworks__exit" ref={exitBlock}></div>
        </div>
    )
}); 