function counter(num, elem, stepBlock, speed) {
    let time = speed;
    let step = stepBlock;
    let e = elem.current;
    let n = 0;
    let t = Math.round(time / (num / step));
    let interval = setInterval(() => {
        n = n + step;
        if (n === num) {
            clearInterval(interval);
        }
        e.innerHTML = n;
    }, t);
}

export default counter;

