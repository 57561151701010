// React
import React from 'react';

// Style
import style from "../CarouselBlock/carouselBlock.scss";


export default React.memo(function CarouselBlock({ item }) {

    return (
        <div className="carouselBlock">
            <div className="block">
                <div className="block__photo">
                    <div className="block__photo_mask">
                        <img src={item.img} alt={item.stepName} />
                        <div className="slider__photo-border"></div>
                    </div>
                    <div className="block__photo_box">
                        0{item.id}
                    </div>
                </div>
                <h3 className="block__title">
                    {item.step} <span>{item.stepName}</span>
                </h3>
                <div className="block__description">
                    {item.description}
                </div>
            </div>
        </div>
    )
}); 