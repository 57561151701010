function interText(words, ref) {
    var letterCount = 1;
    var x = 1;
    var waiting = false;
    var target = ref.current;
    var interval;
    let countWords = words[0].length;


    interval = window.setInterval(function () {
        if (letterCount === 0 && waiting === false) {
            waiting = true;
            target.innerHTML = words[0].substring(0, letterCount)
            window.setTimeout(function () {
                var usedWord = words.shift();
                words.push(usedWord);
                x = 1;
                letterCount += x;

            }, 1000)
        } else if (waiting === false) {
            target.innerHTML = words[0].substring(0, letterCount)
            letterCount += x;
        }
        if (countWords === letterCount) {
            clearInterval(interval);
        }
    }, 40)
}

export default interText;