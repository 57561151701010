// React
import React from 'react';
import ReactDOM from 'react-dom/client';

// Browser router
import { BrowserRouter } from "react-router-dom";

// Style
import './style/main.scss';

// Components
import App from './components/App/App';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </BrowserRouter>
);