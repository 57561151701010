// React
import React, { useEffect } from 'react';

// Function
import { changingProgressBlock } from '../../../../functions/changingProgressBlock';


export default React.memo(function ProgressBlock({ data, id, items, check }) {

    useEffect(() => {
        var progressAllBlocks = document.querySelectorAll([".progress__block"]);
        var nextButton = document.querySelector([".control__next"]);
        var previous = document.querySelector([".control__prev"]);

        changingProgressBlock(progressAllBlocks, nextButton, previous)
    }, []);


    return (
        <div className="progress__block">
            <div className="block">
                <div className="block__data">
                    {id} <span>{data}</span>
                </div>
                <div className="block__steps">
                    <div className="block__steps-line">
                        <div className={`line-active-${check}`}>
                            <div className="line-point-active-1"></div>
                            <div className="line-point-active-2"></div>
                            <div className="line-point-active-3"></div>
                            <div className="line-point-active-4"></div>
                        </div>
                        <div className={check === 1 ? 'line-point-default-1 line-point-active' : 'line-point-default-1'}></div>
                        <div className={check === 2 ? 'line-point-default-2 line-point-active' : 'line-point-default-2'}></div>
                        <div className={check === 3 ? 'line-point-default-3 line-point-active' : 'line-point-default-3'}></div>
                        <div className={check === 4 ? 'line-point-default-4 line-point-active' : 'line-point-default-4'}></div>
                    </div>
                    {items.map((item, id) => (
                        <div key={id} className="block__step">
                            <div className="step__name">{item.title}</div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
});  