// React
import React from 'react';
import { Link } from "react-router-dom";

// Style
import style from './buttonMainFaq.scss';


function ButtonMainFaq({ text, link, }) {
    return (
        <Link className="buttonMainFaq" to={{ pathname: link }}>
            <div className="buttonMainFaq__mask">
                <div className="color">
                    <div className="buttonMainFaq__text">
                        <span>{text}</span>
                    </div>
                </div>
            </div>
        </Link>
    )
}

export default ButtonMainFaq;