// React
import React from 'react';
import { Link } from "react-router-dom";

// Style
import style from './buttonMainHeader.scss';


function ButtonMainHeader({ text, link, }) {
    return (
        <Link className='buttonMainHeader' to={{ pathname: link }}>
            <div className="buttonMainHeader__mask">
                <div className="color">
                    <div className="buttonMainHeader__text">
                        <span>{text}</span>
                    </div>
                </div>
            </div>
        </Link>
    )
}

export default ButtonMainHeader;