export function howItworksSlider(firstItem, secondItem, thirdItem, fourthItem) {
    const cart1 = document.querySelector([".cart_container .cart:nth-child(4)"]),
        cart2 = document.querySelector([".cart_container .cart:nth-child(3)"]),
        cart3 = document.querySelector([".cart_container .cart:nth-child(2)"]),
        cart4 = document.querySelector([".cart_container .cart:nth-child(1)"]);

    cart1.classList.add('cart__100');
    cart2.classList.add('cart__50');
    cart3.classList.add('cart__30');
    cart4.classList.add('cart__10');

    setTimeout(() => {
        let count = 1;
        let doClick = true;
        let num = document.querySelectorAll('.cart').length;
        let cart = document.querySelectorAll('.cart');

        cart.forEach((cart) => {
            cart.addEventListener('click', () => {

                if (!doClick) {
                    return;
                }

                doClick = false;

                if (count < num) {
                    cart.classList.add('bottom');

                    count++;

                } else {
                    cart.classList.add('bottom_last');
                    count++;
                }

                setTimeout(() => {

                    if (count === 2) {
                        cart1.classList.remove('cart__100');
                        cart1.classList.remove('cart__50');
                        cart1.classList.remove('cart__30');
                        cart1.classList.remove('cart__10');

                        cart2.classList.remove('cart__100');
                        cart2.classList.remove('cart__50');
                        cart2.classList.remove('cart__30');
                        cart2.classList.remove('cart__10');

                        cart3.classList.remove('cart__100');
                        cart3.classList.remove('cart__50');
                        cart3.classList.remove('cart__30');
                        cart3.classList.remove('cart__10');

                        cart4.classList.remove('cart__100');
                        cart4.classList.remove('cart__50');
                        cart4.classList.remove('cart__30');
                        cart4.classList.remove('cart__10');

                        cart2.classList.add('cart__100');
                        cart3.classList.add('cart__50');
                        cart4.classList.add('cart__30');
                        cart1.classList.add('cart__10');
                        cart4.style.display = 'block';
                    }

                    if (count === 3) {
                        cart1.classList.remove('cart__100');
                        cart1.classList.remove('cart__50');
                        cart1.classList.remove('cart__30');
                        cart1.classList.remove('cart__10');

                        cart2.classList.remove('cart__100');
                        cart2.classList.remove('cart__50');
                        cart2.classList.remove('cart__30');
                        cart2.classList.remove('cart__10');

                        cart3.classList.remove('cart__100');
                        cart3.classList.remove('cart__50');
                        cart3.classList.remove('cart__30');
                        cart3.classList.remove('cart__10');

                        cart4.classList.remove('cart__100');
                        cart4.classList.remove('cart__50');
                        cart4.classList.remove('cart__30');
                        cart4.classList.remove('cart__10');

                        cart3.classList.add('cart__100');
                        cart4.classList.add('cart__50');
                        cart1.classList.add('cart__30');
                        cart2.classList.add('cart__10');
                    }

                    if (count === 4) {
                        cart1.classList.remove('cart__100');
                        cart1.classList.remove('cart__50');
                        cart1.classList.remove('cart__30');
                        cart1.classList.remove('cart__10');

                        cart2.classList.remove('cart__100');
                        cart2.classList.remove('cart__50');
                        cart2.classList.remove('cart__30');
                        cart2.classList.remove('cart__10');

                        cart3.classList.remove('cart__100');
                        cart3.classList.remove('cart__50');
                        cart3.classList.remove('cart__30');
                        cart3.classList.remove('cart__10');

                        cart4.classList.remove('cart__100');
                        cart4.classList.remove('cart__50');
                        cart4.classList.remove('cart__30');
                        cart4.classList.remove('cart__10');

                        cart4.classList.add('cart__100');
                        cart1.classList.add('cart__50');
                        cart2.classList.add('cart__30');
                        cart3.classList.add('cart__10');
                    }

                    if (count === 5) {
                        cart1.classList.remove('cart__100');
                        cart1.classList.remove('cart__50');
                        cart1.classList.remove('cart__30');
                        cart1.classList.remove('cart__10');

                        cart2.classList.remove('cart__100');
                        cart2.classList.remove('cart__50');
                        cart2.classList.remove('cart__30');
                        cart2.classList.remove('cart__10');

                        cart3.classList.remove('cart__100');
                        cart3.classList.remove('cart__50');
                        cart3.classList.remove('cart__30');
                        cart3.classList.remove('cart__10');

                        cart4.classList.remove('cart__100');
                        cart4.classList.remove('cart__50');
                        cart4.classList.remove('cart__30');
                        cart4.classList.remove('cart__10');

                        cart1.classList.add('cart__100');
                        cart2.classList.add('cart__50');
                        cart3.classList.add('cart__30');
                        cart4.style.display = 'none';
                    }
                }, 700);

                if (count == num + 1) {
                    setTimeout(function () {
                        let cart = document.querySelectorAll('.cart');
                        cart.forEach((cart) => {
                            cart.classList.remove('bottom');
                            cart.classList.remove('bottom_last');
                        });
                        count = 1;
                    }, 1000);
                }

                setTimeout(function () {
                    doClick = true;
                }, 100);
            });
        });

        function stepOne() {
            if (document.querySelector('.process__item')) {
                firstItem.current.className = 'process__item process__item_active';
                secondItem.current.className = 'process__item';
                thirdItem.current.className = 'process__item';
                fourthItem.current.className = 'process__item';
            }
        }

        function stepTwo() {
            if (document.querySelector('.process__item')) {
                firstItem.current.className = 'process__item process__item_active';
                secondItem.current.className = 'process__item process__item_active';
                thirdItem.current.className = 'process__item';
                fourthItem.current.className = 'process__item';
            }
        }

        function stepThree() {
            if (document.querySelector('.process__item')) {
                firstItem.current.className = 'process__item process__item_active';
                secondItem.current.className = 'process__item process__item_active';
                thirdItem.current.className = 'process__item process__item_active';
                fourthItem.current.className = 'process__item';
            }
        }

        function stepFour() {
            if (document.querySelector('.process__item')) {
                firstItem.current.className = 'process__item process__item_active';
                secondItem.current.className = 'process__item process__item_active';
                thirdItem.current.className = 'process__item process__item_active';
                fourthItem.current.className = 'process__item process__item_active';
            }
        }

        function playForCarts() {
            function play() {
                setTimeout(function () {
                    cart[3].click()
                    stepTwo();
                }, 2000);
                setTimeout(function () {
                    cart[2].click()
                    stepThree();
                }, 4000);
                setTimeout(function () {
                    cart[1].click()
                    stepFour();
                }, 6000);
                setTimeout(function () {
                    cart[0].click()
                    stepOne();
                }, 8000);
            }
            play();

            setInterval(function () {
                play();
            }, 8300);
        }
        playForCarts();
    }, 1000);
}